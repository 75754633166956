
import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'momnet/moment';
import { FaMagnifyingGlass } from "react-icons/fa6";
import { startOfDay, subWeeks } from 'date-fns';
import Pagination from 'react-js-pagination';
import axios from 'axios';
import { S3Outposts } from 'aws-sdk';
import { useSelector } from 'react-redux';




const InventoryLog = () => {
  const locationId = useSelector((state) => state.locationId)

  const today = startOfDay(new Date()); // 오늘 날짜
  const oneWeekAgo = startOfDay(subWeeks(new Date(), 1)); // 일주일 전 날짜

  const [dateRange, setDateRange] = useState([oneWeekAgo, today]);
  const [startDate, endDate] = dateRange;
  const [activePage, setActivePage] = useState(1);
  const [totalItemsCount, setTotalItemsCount] = useState(1);
  const [itemsCountPerPage, setItemsCountPerPage] = useState(1);

  const [testMenu, setTestMenu] = useState([]);


  const SearchLog = () => {
    let url = `${process.env.REACT_APP_API_SERVER_URL}/api/v1/inventory/log?page=1&size=12&locationId=${locationId}&sort=ASC&start_at=${dateFormatter(startDate)}&end_at=${dateFormatter(endDate)}`
    requestLogData(url)

  }

  const handlePageChange = (pageNumber) => {

    let url = `${process.env.REACT_APP_API_SERVER_URL}/api/v1/inventory/log?page=${pageNumber}&size=12&locationId=${locationId}&sort=ASC&start_at=${dateFormatter(startDate)}&end_at=${dateFormatter(endDate)}`
    requestLogData(url)
    setActivePage(pageNumber);
  }

  useEffect(() => {
    console.log("startDate : ", dateFormatter(endDate))
    let url = `${process.env.REACT_APP_API_SERVER_URL}/api/v1/inventory/log?page=1&size=12&locationId=${locationId}&sort=ASC&start_at=${dateFormatter(startDate)}&end_at=${dateFormatter(endDate)}`
    requestLogData(url)

  }, [locationId]);

  // 년-월-일 시:분:초 형식으로 변환
  const formattedDate = (date) => {
    return moment(date).format('YYYY년 MM월 DD일');
  }
  // 년-월-일 시:분:초 형식으로 변환
  const formattedHour = (hour) => {
    return moment(hour).format('hh:mmA');

  }

  const dateFormatter = (date) => {
    return moment(date).format("YYYY-MM-DD");
  }

  const requestLogData = (url) => {
    if (url !== "") {
      axios
        .get(url)
        .then((response) => {
          setTestMenu(response.data.data)
          setTotalItemsCount(response.data.totalLogs)
          setItemsCountPerPage(response.data.pageSize)
        })
        .catch((error) => console.error(`Error!!!: ${error}`));
    }

  }

  return (

    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
      <PageTitle>Inventory Log</PageTitle>
      <InventoryContainer>
        <TopContainer >
          <div style={{ display: 'flex', alignItems: "center", marginLeft: '20px', marginRight: 'auto', fontSize: "25px", fontWeight: "bold" }}>Recently Modified History</div>
          <StyledDatePickerWrApriler>
            <DatePicker
              showIcon
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setDateRange(update);
              }}
              isClearable={true}
              dateFormat="yyyy-MM-dd"
            ></DatePicker>

          </StyledDatePickerWrApriler>
          <SubmitButton onClick={SearchLog}><FaMagnifyingGlass />
          </SubmitButton>
        </TopContainer>
        <MiddleContainer>

          <HeaderDiv>
            <NameBox>Ingredient</NameBox>
            <DateBox>Date</DateBox>

            <StockNameBox style={{ marginRight: '65px' }}>Before Quantity</StockNameBox>
            <StockNameBox>After Quantity</StockNameBox>

          </HeaderDiv>

          <ListContainer>
            {
              testMenu.map((item, index) => (

                <ListDiv key={index}>
                  <IngredientName>{item.ingredientName}</IngredientName>
                  <div style={{ background: '', width: '275px', height: '50px' }}>
                    <div style={{ marginBottom: '4px', marginTop: '5px' }}>{formattedDate(item.updatedAt)}</div>
                    <div style={{ fontSize: '14px', color: '#999999' }}>{formattedHour(item.updatedAt)}</div>
                  </div>
                  <div style={{ width: '290px' }}>
                    {item.beforeQuantity}{item.unit}
                  </div>
                  <div>
                    {item.afterQuantity}{item.unit}
                  </div>
                </ListDiv>

              )
              )}
          </ListContainer>
        </MiddleContainer>
        <BottomContainer>
          <PaginationBox>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              pageRangeDisplayed={5}
              prevPageText={"<"}
              nextPageText={">"}
              onChange={handlePageChange} >
            </Pagination>
          </PaginationBox>
        </BottomContainer>
      </InventoryContainer>
    </div >
  )

}

export default InventoryLog;


const PageTitle = styled.div`
font-size: 30px;
font-weight: bold;
margin-left: 25px;
margin-top: 20px;
width: 500px;
`

const InventoryContainer = styled.div`
width: 1200px;
height: 726px;
margin-left: 20px;
margin-top: 20px; 
`

const TopContainer = styled.div`
display: flex;
justify-content: right;

maring-left: 10px;
width: 100%;
height: 80px;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);

`

const MiddleContainer = styled.div`
width: 100%;
height: 566px;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);

`

const BottomContainer = styled.div`
width: 100%;
height: 80px;
background: white;
box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25); /* 아래 그림자만 남김 */
border-top: 2px dashed #F5F5F5;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;

`

const StockNameBox = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;

font-weight: bold;
padding-left: 7px;
// background: blue;
width: 120px;
height: 15px;
margin-right: 150px; 
margin-left: auto;

border-right: 2px solid;
border-left: 2px solid;
border-color: transparent;


`


const NameBox = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 7px;
font-weight: bold;

width: 200px;
margin-left: 10px;
border-left: 2px solid transparent;
border-left: 2px solid transparent;
`
const DateBox = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 7px;
font-weight: bold;

width: 40px;
margin-left: 180px;
margin-right: auto;
border-left: 2px solid transparent;
border-left: 2px solid transparent;
`

const HeaderDiv = styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
height: 50px;
background: #F5F5F5;
align-items: center;
`

const ListContainer = styled.div`
width: 100%;
height: 516px;
background: white;
max-height: 516px; /* 최대 높이 설정 */
overflow: auto; /* 내용이 넘치면 스크롤이 생김 */
overflow-x: hidden;
`


const ListDiv = styled.div`
position: relative;
display: flex;
align-items: center;
// margin-left: 13.5px;
width: 1200px;
height: 80px;
background: white;
border-bottom: 2px dashed #F5F5F5; /* 점선 테두리, 회색 */

&:hover {
    background: #F5F5F5;
}
`

const IngredientName = styled.div`
white-space: pre-wrap;
padding-left: 30px;
margin-left: 10px;
width: 350px;
`

const SubmitButton = styled.button`
width: 100px;
height: 50px;

margin-top: 13px;
margin-right: 10px;
background: white;
border: 0px solid black;
box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 5px;
font-size: 20px;

&:active {
  // background: gray;
  box-shadow: none;
  border: 2px solid #CCCCCC;

}
`

const StyledDatePickerWrApriler = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 240px;

  &:focus-within { /* 포커스가 내부의 요소에 적용될 때 */
  border: 3px;
  }

  .react-datepicker {
    display: flex;
    justify-content: center;
    border: 0px solid black;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    background-color: white;
  }

  .react-datepicker__navigation{
    margin-top: 6px;
  }

  .react-datepicker__header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: white;
  }

  .react-datepicker__input-container{
    margin-top: 12px;
  }

  .react-datepicker__input-container input{ 
    border-radius: 5px;
    border: 0px;
    height: 40px;
    width: 175px;
    font-size: 15px;
    padding-left: 30px;
    padding-right: 3px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);
  }


  .react-datepicker__input-container input:focus{
    border-color: red; /* 포커스 시 테두리 색상 변경 */
    outline: 2px solid gray;
  }

  .react-datepicker__close-icon{
    display: none;
  }

  .react-datepicker__month-container{
    display: flex;
    flex-direction: column;
    width: 300px;
  }

  .react-datepicker__current-month{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
  }

  .selected-day {
    background: #2E1C8B;
    border-radius: 50%;
    font-weight: 700;
  }


  .react-datepicker__day-names{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    height: 40px;

    font-size: 17px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .react-datepicker__month {
    display: flex;
    justify-content: left;
    flex-direction: column;
  }

  .react-datepicker__week{
    height: 36px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 17px;
  }

  .react-datepicker__calendar-icon{
    // background: red;
    margin-top: 10px;
    margin-left: px;
  }

  .react-datepicker__day--keyboard-selected{ // 맨처음 오늘 날짜 표시 색
    background: #ebf1fa;
   }

  .react-datepicker__day--in-selecting-range{ // 드래그 된 날짜 색
    background: #537ab1;

   }

  .react-datepicker__day--in-range{ // 셀렉된 날짜 색
    background: #3a589e;

   }

   .react-datepicker__day:hover{ // 호버 된 색
    background: #a2b9d4;
   }

   .react-datepicker__day--range-start{ // 셀렉 시작 날짜
    background: #a2b9d4;
    color: white;
   }

   .react-datepicker__day--range-end{ // 셀렉 끝나는 날짜
    background: #a2b9d4;
    color: white;
   }
  `


const PaginationBox = styled.div`
  .pagination { display: flex; justify-content: center; margin-top: 15px;}
  ul { list-style: none; padding: 0;}
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 0px solid #e2e2e2;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem; 
    font-weight:bold;

  }
  ul.pagination li:first-child{ border-radius: 5px 0 0 5px;  }
  ul.pagination li:last-child{ border-radius: 0 5px 5px 0;}
  ul.pagination li a { text-decoration: none; color: #959ca0; font-size: 1rem; }
  ul.pagination li.active a { color: black;}
  ul.pagination li.active { color: red; }
  ul.pagination li a:hover { color: grey },
  ul.pagination li a.active { color: blue; }
`