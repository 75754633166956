import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { BsThreeDotsVertical } from "react-icons/bs";



const EditModalComponent = ({ items, open, changeValue, selectedIngredient}) => {
  const [totalValue, setTotalValue] = useState('');
  const [currentValue, setCurrentValue] = useState('');

  if(!open){
    return null;
  }
  console.log(selectedIngredient, "====================================");
  const handleClick = () => {
    setTotalValue('');
    setCurrentValue('');

    changeValue(); //props 로 전달된 handleChangeValue 함수 새로운 이름
  };

  const handleConfirmButton = () => {
    if(currentValue > parseInt(selectedIngredient.totalQuantity)){
      alert("현재 재고량보다 전체 재고량이 커야합니다.")
      return;
    }


    // 확인시 API 요청 보내야함.
    selectedIngredient.currentQuantity = currentValue;
    
    if(totalValue !== ''){
      selectedIngredient.totalQuantity = totalValue;
    }
    

    console.log("selectedIngredient :   ",selectedIngredient)

    const test = selectedIngredient;

    test.currentQuantity = parseInt(currentValue)
    
    if(totalValue !== ''){
      console.log("욕하지마~")
      test.totalQuantity = parseInt(totalValue)
    }

    handleButtonTrueClick(test);
    changeValue();
  };

  const handleTotalChange = (e) => {
    const inputValue = e.target.value;

    // 입력된 값이 숫자가 아닌 경우
    if (isNaN(inputValue)) {
        alert('숫자만 입력해주세요!');
        return;
    }
    setTotalValue(e.target.value);
  }

  const handleCurrentChange = (e) => {
    const inputValue = e.target.value;

    // 입력된 값이 숫자가 아닌 경우
    if (isNaN(inputValue)) {
        alert('숫자만 입력해주세요!');
        return;
    }
    setCurrentValue(e.target.value);
  }

 const handleButtonTrueClick = (data) => {
  console.log("patch : ", data);
  axios
    .patch(`${process.env.REACT_APP_API_SERVER_URL}/api/v1/inventory`, data, {
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*', // 서버에서 허용할 도메인 설정
      }
    })
    .then((response) => {
      console.log("패치 성공이다~!~!~!이얏호~!");
      window.location.reload() 
    })
    .catch((error) => {
      console.log(error);
    });
};



  return (
    <>
      <EditModal>
        <div style={{ width: '100%', height: '90px', display: 'flex', justifyContent: 'left', marginLeft: '40px', marginTop: '30px', marginBottom: '-10px', fontSize: '35px', fontWeight: 'bold' }}>Edit</div>
        <>
          <InputContainer >
            <InventoryName>Total</InventoryName>
            <InventoryInput placeholder={selectedIngredient.totalQuantity} onChange={handleTotalChange} value={totalValue}></InventoryInput>
          </InputContainer>
          <InputContainer >
            <InventoryName>current</InventoryName>
            <InventoryInput placeholder={selectedIngredient.currentQuantity}onChange={handleCurrentChange} value={currentValue} ></InventoryInput>
          </InputContainer>
        </>
        <div style={{display: 'flex', justifyContent: 'flex-start', height: "50px", marginTop:"30px"}}>
          <CancelButton1 onClick={handleClick}>취소</CancelButton1>
          <CancelButton1 onClick={handleConfirmButton}>확인</CancelButton1>

        </div>
      </EditModal>
    </>

  );
};

export default EditModalComponent;





const EditModal = styled.div`   
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  width: 380px;
  height: 340px;
  background: white;
  border-radius: 5px;
  position: fixed;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);


  z-index: 5;
  left: 550px;; 

  animation: modalShow 0.2s linear;
  @keyframes modalShow {
    from {
      transform: scale(0);

   }
   to {
       transform: scale(1);   

    }
    }
`;

const InputContainer = styled.div`
display: flex;
background: white;
height: 40px;
margin-bottom: 25px;


`


const CancelButton1 = styled.button`
  display: block;
  justify-content: center;
  font-size: 20px;
  margin-top: 3px;
  text-align: center;
  color: gray;
  width: 48%;
  margin-left: 5px;
  border: 0px;
  border-radius: 10px;
  background: white;

&: hover {
  background: #F5F5F5;
  font-weight: bold;
}
`

const InventoryInput = styled.input`
  width: 150px;
  margin-left: auto;
  margin-right: 20px;
  text-align: center;
  font-size: 20px; 
  border: 0px;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);


  &:focus::placeholder {
    color: transparent; /* 포커스되었을 때 placeholder 투명하게 설정 */
  }

  &:focus {
    outline: none; /* 포커스 효과 제거 */
    border: 1px normal black;
  }
`;

const InventoryDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 100%;
  background: white;
  border-radius: 2px;
  // border: 1px solid gray;
  margin-left: auto;
  margin-right: 20px;
  text-align: center;
  font-size: 20px; 
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);



`;


const InventoryName = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 40px;
  font-size: 20px;
  font-weight: bold;
  background: white;
  color: gray;
  



`;