import React, { useState, useEffect } from "react";
import styled from "styled-components";



function D_Tab1({ socket, setMessage, message}) { 
  const data = {
    "function": "dashboard",
    "value": {
      "category" : 1,
      "button" : "syrup1",
      "totalquantity" : 100,
      "remainquantity" : 50
    }
  }

  const handleResetClick = () => {
    if (!socket) {          
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"dashboard",
      "value": { button: "reset", value: 1 }
      };

    socket.send(JSON.stringify(data1));
    console.log("reset 데이터 전송");

  };

  return (
    <Box>
    <div style={{ display: "flex", flexDirection: "row" }}>

    <div style={{ display: "flex", flexDirection: "column" }}>
      <ButtonRequest>데이터 <br/> 요청</ButtonRequest>
      <label style={{ margin: "30px 0 0 85px"}}>전체개수</label>
      <label style={{ margin: "30px 0 0 85px"}}>남은개수</label>
    </div>

    <div>
    <BoxData>
    <LabelName>{data.value.button}</LabelName>
    <LabelQuantity style={{ marginTop: "135px"}}> {data.value.totalquantity} </LabelQuantity>
    <LabelQuantity style={{ marginTop: "15px"}}> {data.value.remainquantity} </LabelQuantity>
    <ButtonReset 
    style={{ marginTop: "15px"}}
    onClick={handleResetClick}>
      FULL</ButtonReset>

    </BoxData> 
    </div>

    </div>

    </Box>
  );
}

export default D_Tab1;

const Box = styled.div`
  width: 1260px;
  height: 730px;
  background: #f0f0f0;
`;

const BoxData = styled.div`
display: flex;
flex-direction: column;
margin: 85px 0 0 0px;

`;

const ButtonRequest = styled.button`
font-family: 'Inter';
font-style: normal;
font-weight: bold;
font-size: 32px;
  margin: 85px 0 0 30px;
  width: 180px;
  height: 150px;
  border-radius: 8px;
  transition: 0.5s;
  background-color: #FFDBD2;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  &:hover {
    cursor: pointer;
    background-color: #fff8f6;
    border: 1px solid #f84d27;
  }
`;

const LabelName = styled.label`
  font-size: 20px;
  margin: 0 0 0 50px;
  align-items: center;
  justify-content: center;
  display: flex;

`;

const LabelQuantity = styled.label`
font-weight: 600;
font-size: 24px;
display: flex;
  align-items: center;
  justify-content: center;

margin: 0 0 0 50px;
border-radius: 8px;
background-color: #ffffff;
width: 95px;
height: 50px;
`;

const ButtonReset = styled.button`
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  width: 95px;
  height: 50px;
  border-radius: 8px;
  transition: 0.5s;
  background-color: #FFDBD2;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  margin: 0 0 0 50px;

  &:hover {
    cursor: pointer;
    background-color: #fff8f6;
    border: 1px solid #f84d27;
  }
`;

