import React, { useContext } from 'react';
import Limage from "../../assets/Logo.png";

import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { ButtonReDoContext } from '../../ButtonReDoContext';
import { changeLocationId } from '../../store.js'
import { useDispatch } from 'react-redux';
import { useSelector } from "react-redux"
import SidebarDrowDown from './SidebarDropDown.js' 


function Sidebar({ }) { //  showButtonReDo 상태를 Sidebar 컴포넌트에 전달
  let redux = useSelector((state) => state)
  let dispatch = useDispatch();

  const { showButtonReDo } = useContext(ButtonReDoContext);

  const menus = [
    { name: "Cleaning Mode", path: "/cleaningmode" },
    { name: "Menuㅤ", path: "/menumanagement", }, //  showButtonReDo 값이 true일 경우, 해당 메뉴들을 클릭하지 못하게
    { name: "Add Menu", path: "/addmenu", },
    { name: "메뉴 품절 관리", path: "/appcontrol", },
    { name: "성분 재고 관리", path: "/inventory", },

    // { name: "Dynamodb wControl", path: "/dynamodbcontrol",  },

    // { name: "Inventory Dashboard", path: "/inventorydashboard",  },
  ];

  const handleOnClick = (e) => {
    // console.log("sidebar : ", e)

    dispatch(changeLocationId(e))
  }

  return (
    <Side>
      <Box1>
        <Label>Manager</Label>
      </Box1>
      <Box2>
        <SidebarDrowDown handleOnClick = {handleOnClick}>

        </SidebarDrowDown>
        {/* <Select onChange={(e) => {
          handleOnClick(e.target.value)
        }}>
            <OptionLocation value="1" > 성수점ㅤ</OptionLocation>
            <OptionLocation value="2"> 인사동점ㅤ</OptionLocation>
        </Select> */}
      </Box2>

      <Menu>
        {menus.map((menu, index) => {
          return (
            <StyledNavLink
              exact
              to={menu.path}
              key={index}
              $activeClassName="active"
              disabled={showButtonReDo}
            >
              <div className={`sidebar-item ${showButtonReDo ? 'disabled' : ''}`}>
                <p>{menu.name}</p>
              </div>
            </StyledNavLink>
          );
        })}
      </Menu>

      <StyledImage src={Limage} />
    </Side>
  );
}

export default Sidebar;


const Side = styled.div`
  font-size: 20px;
  background-color: #3d3d3d;
  flex-direction: column;

  width: 172px;
  height: 802px;
  z-index: 1;
`;

const Box1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: 80px;
`;

const Label = styled.label`
  font-size: 32px;
  color: white;
`;

const Box2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 172px;
  height: ${(props) => (props.isExpanded ? "105px" : "65px")};
  margin-bottom: ${(props) => (props.isExpanded ? "10px" : "")};
  transition: height 0.5s ease-in-out, margin-bottom 0.5s ease-in-out;
`;



const Select = styled.select`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-left: 5px;
  position: absolute;
  color: #a6a6a6;
  
  font-size: 18px;
  background-color: #1e1e1e;
  border: 1px solid #818181;
  border-radius: 8px;
  width: 134px;
  height: 40px;
  
`;

const OptionLocation = styled.option`
  display: flex;
  flex-direction: column;
  justify-content: center;

`

const Menu = styled.div`
  color: black;
  width: 172px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
`;

const StyledNavLink = styled(Link)`
  font-size:20px; 
  color: #B9B9B9;
  text-decoration: none;


  display: block; 
  align-items: center; 
  justify-content : flex-end;	
  z-index:1; 
  &.active {
    display: flex;
    align-items: center; 
    justify-content: right; 
    width: 157.5px;
    height: 66px;
    color: white;
    background-color: #171717; 
    transition: 0.5s;
    padding: 7px;
ㅤ    z-index:1; 
  }
`;


const StyledImage = styled.img`
  width: 70px;
  height: 34px;
  margin: 500px 0 0 50px;
`;

