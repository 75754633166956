import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import Swal from "sweetalert2";


import { ButtonReDoContext } from '../../ButtonReDoContext';

export default function LeftContainer({ socket, socketStatus, setSocketStatus, socketOnImage, socketOffImage }) {
  const [message, setMessage] = useState('');

  const [showButtonClean, setShowButtonClean] = useState(true);
  const { showButtonReDo, setShowButtonReDo } = useContext(ButtonReDoContext);

  useEffect(() => {
    if (socket) {
      setSocketStatus({ image: socketOnImage, text: 'ㅤSocket ON' });
    } else {
      setSocketStatus({ image: socketOffImage, text: 'ㅤSocket OFF' });
    }
  }, [socket]);

  const handleCleanClick = () => {
    Swal.fire({
      title: "일시정지 하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const data1 = {
          "function": "clean",
          "value": {
            "button": "clean_mode_on",
            "value": 1
          }
        };
  
        if (!socket) {
          console.log("WebSocket이 연결되지 않았습니다.");
          return;
        }
  
        socket.send(JSON.stringify(data1));
        setMessage('클린모드가 시작되었습니다.');

        setShowButtonClean(false);
        setShowButtonReDo(true);
      }
    });
  };

  const handleReDoClick = () => {
    Swal.fire({
      title: "재실행 하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const data1 = {
          "function": "clean",
          "value": {
            "button": "clean_mode_off",
            "value": 1
          }
        };
  
        if (!socket) {
          console.log("WebSocket이 연결되지 않았습니다.");
          return;
        }
  
        socket.send(JSON.stringify(data1));
        setMessage('클린모드가 종료되었습니다.');

        setShowButtonClean(true);
        setShowButtonReDo(false);
      }
    });
  };

  const handleResetClick = () => {
    Swal.fire({
      title: "로봇을 리셋하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const data2 = {
          "function": "clean",
          "value": {    
            "button": "robot_reset",  
            "value": 1
          }
        };
  
        if (!socket) {
          console.log("WebSocket이 연결되지 않았습니다.");
          return;
        }
  
        socket.send(JSON.stringify(data2));
        setMessage('로봇리셋 버튼이 클릭되었습니다.');

        setShowButtonClean(false);
        setShowButtonReDo(true);
      }
    });
  };

  const handleStopClick = () => {
    Swal.fire({
      title: "긴급정지 하시겠습니까?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const data3 = {
          "function": "clean",
          "value": {    
            "button": "robot_stop",  
            "value": 1,
          }
        };

        if (!socket) {
          console.log("WebSocket이 연결되지 않았습니다.");
          return;
        }

        socket.send(JSON.stringify(data3));
        setMessage('긴급정지 버튼이 클릭되었습니다.');

        setShowButtonClean(false);
        setShowButtonReDo(true);
      }
    });
  };

  const handleButtonOPENClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }        
    
    const data4 = {
      "function": "clean",
      "value": {    
        "button": "door_open",  
        "value": 1
      }
    };

    socket.send(JSON.stringify(data4));
    setMessage('도어 열림 버튼이 클릭되었습니다.');
  };

  const handleButtonCLOSEClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }    
    
    const data5 = {
      "function": "clean",
      "value": {    
        "button": "door_open",  
        "value": 1,
      }
    };

    socket.send(JSON.stringify(data5));
    setMessage('도어 닫힘 버튼이 클릭되었습니다.');
  };

  return (
    <Container>
      {showButtonClean && (
        <ButtonClean onClick={handleCleanClick}>
          일시정지
        </ButtonClean>
      )}
      {showButtonReDo && (
        <ButtonReDo onClick={handleReDoClick}>
          재실행
        </ButtonReDo>
      )}
    
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ButtonWindow onClick={handleButtonOPENClick}>Door<br/>OPEN</ButtonWindow>
        <ButtonWindow onClick={handleButtonCLOSEClick}>Door<br/>CLOSE</ButtonWindow>
      </div>

      <Box>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {socketStatus.image && <img src={socketStatus.image} alt="Socket Status" />}
        <span>{socketStatus.text}</span>
      </div>
        <br/>
        {message}
      </Box>
      <Button2 onClick={handleResetClick}>로봇리셋</Button2>
      <Button3 onClick={handleStopClick}>긴급정지</Button3>
    </Container>
  );
}


const Container = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  width: 210px;
`;

const ButtonClean = styled.button`
  background-color: #5cae4e;
  font-size: 32px;
  font-weight: bold;
  color: black;
  border: none;
  margin: 0 5px 5px 5px;
  border-radius: 8px;
  width: 200px;
  height: 185px;
  transition: 0.5s;

  &:hover {
    background-color: #71ef5c;
    cursor: pointer;
    border: 2px solid #000000;
  }
`;

const ButtonReDo = styled.button`
  background-color: #F2F551;
  font-size: 32px;
  font-weight: bold;
  color: black;
  border: none;
  margin: 0 5px 5px 5px;
  border-radius: 8px;
  width: 200px;
  height: 185px;
  transition: 0.5s;

  &:hover {
    background-color: #D0D252;
    cursor: pointer;
    border: 2px solid #000000;
  }
`;

const ButtonWindow = styled.button`
  background-color: #BAC8FC;
  font-size: 22px;
  font-weight: bold;
  color: black;
  border: none;
  margin: 0px 0px 5px 5px;
  border-radius: 8px;
  width: 96px;
  height: 100px;

  &:hover {
    background-color: #BDC8F0;
    cursor: pointer;
    border: 2px solid #000000;
  }
`;
const Box = styled.div`
  width: 160px;
  height: 170px;
  background-color: #d9d9d9;
  margin: 0 5px 5px 5px;
  border-radius: 8px;
  transition: 0.5s;
  padding: 20px;
  font-size: 16px;
`;

const Button2 = styled.button`
  background-color: #fdcabe;
  color: black;
  padding: 10px;
  border: none;
  border-radius: 8px;
  width: 200px;
  height: 204px;
  margin: 0 5px 5px 5px;
  font-size: 36px;
  font-weight: bold;
  transition: 0.5s;

  &:hover {
    background-color: #ffe6e0;
    cursor: pointer;
    border: 2px solid #f84d27;
    color: #f84d27;
  }
`;

const Button3 = styled.button`
  background-color: #f84d27;
  color: black;
  padding: 10px;
  border: none;
  border-radius: 8px;
  margin: 0 5px 5px 5px;
  width: 200px;
  height: 202px;
  transition: 0.5s;
  font-weight: bold;
  font-size: 36px;

  &:hover {
    background-color: #d03310;
    cursor: pointer;
    border: 2px solid #000000;
    color: #ffffff;
  }
`;