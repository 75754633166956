import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const DropdownButton = ({ items, changeIsCheckedList }) => {
  const [currentValue, setCurrentValue] = useState([]);
  const [showOptions, setShowOptions] = useState(false);


  const wrapperRef = useRef();



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false)
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {

      document.removeEventListener('click', handleClickOutside);
    };
  }, [wrapperRef]);


  const handleTest = (e, id) => {
    if (e.currentTarget !== e.target) { return console.log("후잉"); }
    if (items[id]) {

      let newStock = items[id];
      const verifyDuplicate = currentValue.find((x) => {
        return x.id == items[id].id
      })

      if (verifyDuplicate) {
        console.log("ID가 이미 존재합니다.")
        newStock.isChecked = false;
        const filteredArray = currentValue.filter((x) => {
          return x.id !== items[id].id;
        });
        setCurrentValue(filteredArray)


        items[id].isChecked = false
        let updateItems = [...items]
        changeIsCheckedList(updateItems)

      } else {
        console.log("ID가 존재하지 않습니다.");
        newStock.isChecked = true;
        setCurrentValue(currentValue => [...currentValue, newStock])
        items[id].isChecked = !items.isChecked

        let updateItems = [...items]
        changeIsCheckedList(updateItems)
      }

    }
  }

  return (

    <SelectBox show={showOptions} ref={wrapperRef} onClick={() => setShowOptions((prev) => (!prev))}>
      <LabelContainer >
        <Label >  {currentValue.length > 0
          ? currentValue.map(obj => obj.name).join(', ')
          : "Stock"}</Label>
      </LabelContainer>
      <SelectOptions show={showOptions} >
        {items.map((item, index) =>
          <CheckboxContainer onClick={(e) => { e.stopPropagation() }}>
            <StockLabel isChecked={item.isChecked} key={index} htmlFor={'item' + index}  >
              <Checkbox type='checkbox' id={'item' + index} onChange={(e) => { handleTest(e, item.id); e.stopPropagation() }} />

              {item.name}
            </StockLabel>
          </CheckboxContainer>
        )}
      </SelectOptions>

    </SelectBox>
  );
};

export default DropdownButton;


const SelectBox = styled.div`
  z-index: 1;
  position: relative;
  margin-left: 10px;
  width: 200px;
  height: 30px;
  padding: 8px;
  border-radius: 5px;
  background-color: #ffffff;
  align-self: center;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  content: "⌵";
  

  &::before {
    content: "⌵";
    position: absolute;
    top: ${props => props.show ? '14px' : '6px'};
    right: 8px;
    color: gray;
    font-size: 20px;
    transform: ${props => props.show ? 'rotate(180deg)' : 'rotate(0)'};
  }

  border: 1px solid transparent; /* 기본 테두리 설정 */


  &:hover {
    border-color: gray; /* 마우스를 올릴 때 테두리 색상 변경 */ 
  }
`;

const LabelContainer = styled.div`
	  display: flex;
    justify-content: center;
    max-width: 170px;
    overflow: hidden;
`

const Label = styled.div`
  display: block;
  justify-content: center;
  font-size: 20px;
  margin-top: 3px;
  text-align: center;
  color: gray;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;


`;
const SelectOptions = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  position: absolute;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);

  list-style: none;
  top: 33px;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 170px;
  max-height: ${(props) => (props.show ? "none" : "0")};
  padding: 0;
  border-radius: 5px;
  background-color: white;
  color: black;
`;



const Checkbox = styled.input`
  background: blue;
  margin-right: 10px;
  margin-top: 0px;
  margin-left: 8px;
  transform: scale(1.4);
`;


const StockLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 210px;
  height: 50px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 3px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: normal;
  background: ${props => props.isChecked ? '#DDDDDD' : '6px'};

//   background: green;

  transition: background-color 0.2s ease-in;
  &:hover {
    // width: 100px;
    background-color: #DDDDDD;
    border-radius: 5px;
    font-weight: bold;
  }
`
const CheckboxContainer = styled.div`
  display: flex;
  margin-top: 2px;
//   background: red;
  width: 220px;
  height: 60px;
`
