import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Limage from "../../assets/Logo.png";
import Bimage from "../../assets/Background.png";
import axios from "axios";
import base64 from 'base-64';

export default function LogIn() {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState(""); // password 상태 선언


  const handleLogin = async () => {
    try {
      const response = await fetch("https://robros-api.com/api/v1/auth/login", { // 이 부분 배포할 때 꼭 고쳐야함 !!!!!!!
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username,
          password,
        }),
      });
  
      handleLoginResponse(response, response.status);
      
    } catch (error) {
      if(error instanceof TypeError && error.message.includes("Failed to fetch")){
        window.alert("서버에서 응답이 없습니다.")
      }
      console.error("비상!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      console.error("Error during login:", error);
    }
  };
  
  const handleLoginResponse = (response, status) => {
    if (status == 200) {
      handleSuccessfulLogin(response);
    } else {
      
      handleFailedLogin(status);
    }
  };
  
  const handleSuccessfulLogin = (response) => {
    sessionStorage.clear();
    console.log(response.headers);
  
    const token = response.headers.get("Authorization");

    if (token) {
      sessionStorage.setItem('token', token);
      setTokenExpiry();
      setAuthority();
      navigate("/cleaningmode");
    } else {
      console.error("Authorization token not found in the response.");
    }
  };
  
  const setTokenExpiry = () => {
    const now = new Date();
    const expiryTime = now.getTime() + (180 * 60 * 1000); // 180분
    sessionStorage.setItem('expiry', expiryTime);
    console.log(sessionStorage.getItem('expiry'));
  };
  
  const setAuthority = () => {
    const token = sessionStorage.getItem('token');
    if (token) {
      const decodedToken = base64.decode(token.substring(token.indexOf('.') + 1, token.lastIndexOf('.')));
      const authority = JSON.parse(decodedToken).roles[0];
      sessionStorage.setItem('authority', authority);
    }
  };
  
  const handleFailedLogin = (status) => {
    console.log(status)
    if(status == "401"){
      console.log('401')
      window.alert('401 Unauthorized 권한이 없습니다')
      window.alert("오늘의 유머 : 이탈리아의 날씨는? .... 습하게띠..? 엌ㅋㅋㅋㅋㅋㅋㅋ ");
    }else if(status == "500"){
      window.alert('500 Internal Server Error')
    }else{
      console.error("Login failed");
    // window.alert(" 틀렸삼 ㅋㅋ")
      window.alert("오늘의 유머 : 이탈리아의 날씨는? .... 습하게띠..? 엌ㅋㅋㅋㅋㅋㅋㅋ ");
    }
    
  };
 


  const handlePasswordChange = (e) => {
    // password 변경하는 함수 선언
    setPassword(e.target.value);
  };

  const handleUsernameChange = (e) => {
    // Update the username state
    setUsername(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // 엔터 키를 눌렀을 때 handleLogin 함수 호출
      handleLogin();
    }
  };

  return (
    <Container>
      <FormContainer>
        <StyledInput 
         type="text" 
         placeholder="ID" 
         onChange={handleUsernameChange}
         value={username}/>
        <StyledInput
          type="password"
          placeholder="Password"
          onChange={handlePasswordChange}
          onKeyPress={handleKeyPress} 
        />
        <StyledButton onClick={handleLogin}>LOGIN</StyledButton>
      </FormContainer>
      <StyledImage src={Limage} />
    </Container>
  );
}

const Container = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 802px;
  width: 1438px;
  background-image: url(${Bimage});
`;
const FormContainer = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 140px;
`;

const StyledInput = styled.input`
  font-size: 24px;
  background-color: white;
  height: 75px;
  width: 368px;
  margin: 7.5px auto;
  padding-left: 15px;
  border-radius: 12px;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari */
    color: lightgray;
  }

  border: none;
`;

const StyledButton = styled.button`
  background-color: #f84d27;
  color: white;
  font-size: 32px;
  height: 75px;
  width: 384px;
  margin: 25px 0 0 0;
  border: none;
  transition: 0.5s;
  border-radius: 12px;
  font-weight: bold;

  &:hover {
    background-color: lightgray;
    cursor: pointer;
  }
`;

const StyledImage = styled.img`
  margin: 184px 0 0 0px;
`;