import React, { useState, useEffect } from "react";
import styled from "styled-components";

function GridButtonHot({ onClick, valueObject }) {

}

function Tab2({ socket }) { 
  
  const [message, setMessage] = useState("");


  return (
    <Box1>
      
    </Box1>
  );
}

export default Tab2;

const Box1 = styled.div`
  display: flex;
  align-items: center;
  width: 1260px;
  height: 730px;
  background: #f0f0f0;
  flex-direction: column;
`;
