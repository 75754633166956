import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const LocationDropDown = ({ setLocation }) => {
    const [currentValue, setCurrentValue] = useState(null);
    const [showOptions, setShowOptions] = useState(false);
    const locationList = [{ "locationId": 1, "name": "성수점" }, { "locationId": 2, "name": "인사동점" }]

    const wrapperRef = useRef();



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowOptions(false)
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {

            document.removeEventListener('click', handleClickOutside);
        };
    }, [wrapperRef]);

    const clickOption = (index) => {
        setLocation(index)
        setShowOptions((prev) => (!prev))
        setCurrentValue(index)
    }



    return (

        <SelectBox show={showOptions} ref={wrapperRef} onClick={() => setShowOptions((prev) => (!prev))}>
            <LabelContainer >
                <Label>{currentValue !== null
                    ? locationList[currentValue-1].name
                    : "Location"}</Label>
            </LabelContainer>
            <SelectOptions show={showOptions} >
                {locationList.map((item, index) =>
                    <CheckboxContainer onClick={(e) => { e.stopPropagation() }}>
                        <StockLabel key={index} htmlFor={'item' + index} onClick={() => clickOption(item.locationId)} >
                            {item.name}
                        </StockLabel>
                    </CheckboxContainer>
                )}
            </SelectOptions>

        </SelectBox>
    );
};

export default LocationDropDown;


const SelectBox = styled.div`
    display: flex;
    justify-content: center;
    z-index: 1;
    position: relative;
    width: 110px;
    height: 47px;
    padding: 8px;
    border-radius: 16px;  
    background-color: #ffffff;
    align-self: center;
    border: 1px solid #cccccc;  

    cursor: pointer;
    content: "⌵";
    

  &::before {
    content: "⌵";
    position: absolute;
    top: ${props => props.show ? '22px' : '16px'};
    right: 8px;
    color: gray;
    font-size: 20px;
    transform: ${props => props.show ? 'rotate(180deg)' : 'rotate(0)'};
  }



  &:hover {
    border-color: gray; /* 마우스를 올릴 때 테두리 색상 변경 */ 
  }
`;

const LabelContainer = styled.div`
	  display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 170px;
    overflow: hidden;
    
`

const Label = styled.div`
  display: block;
  justify-content: center;

  font-size: 20px;
  margin-top: 3px;
  text-align: center;
  color: gray;

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const SelectOptions = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  position: absolute;
  border: ${(props) => (props.show ? "1px solid #cccccc" : "0px solid #cccccc")};
  list-style: none;
  top: 50px;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 140px;
  
  max-height: ${(props) => (props.show ? "none" : "0")};
  padding: 0;
  background-color: white;
  border-radius: 16px;  
  color: black;

`;



const StockLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 3px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: normal;
  background: ${props => props.isChecked ? '#DDDDDD' : '6px'};


  transition: background-color 0.2s ease-in;
  &:hover {
    background-color: #DDDDDD;
    border-radius: 5px;
    font-weight: bold;
  }
`
const CheckboxContainer = styled.div`
  display: flex;
  margin-top: 2px;
//   background: red;
  width: 122px;
  height: 60px;
  
`
