// ButtonReDoContext.js

import React, { createContext, useState } from 'react';

export const ButtonReDoContext = createContext();

export const ButtonReDoProvider = ({ children }) => {
  const [showButtonReDo, setShowButtonReDo] = useState(false);

  return (
    <ButtonReDoContext.Provider value={{ showButtonReDo, setShowButtonReDo }}>
      {children}
    </ButtonReDoContext.Provider>
  );
};
