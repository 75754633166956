import React from 'react';
import { Navigate, Outlet  } from 'react-router-dom';



const PrivateRoute = ({component: Component}) => {
    const authority = sessionStorage.getItem('authority')   
    const now = new Date()

    console.log(sessionStorage.getItem('expiry'))
   
    if(sessionStorage.getItem('expiry') != null && sessionStorage.getItem('expiry') != false ){
        if(now.getTime() > sessionStorage.getItem('expiry')){
            console.log("--- 만료 ---")
            sessionStorage.clear()
            return <Navigate to='/'/>;
        }
        console.log("만료되기 전입니다.")
    }

   if(authority !== 'ADMIN'){

    return ( authority === 'ADMIN') ? <Outlet/> : <Navigate to='/'/>;  

   
    } else if( authority === 'ADMIN') {

       return  ( authority === 'ADMIN') ? <Outlet/> : <Navigate to='/'/>; 
   }

};

export default PrivateRoute;
