import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { FaBeer } from "@react-icons/all-files/fa/FaBeer";
import { FaMagnifyingGlass } from "react-icons/fa6";

const SearchButton = ({ search }) => {
  const [searchText, setSearchText] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
    search(e.target.value)
  }

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (

    <SelectBox >
      <GlassContainer >
        <FaMagnifyingGlass style={{ cursor: 'default' }} />
      </GlassContainer>
      <SearchInput
        value={searchText}
        onFocus={handleFocus}
        placeholder={isFocused ? "" : "Search..."}
        onChange={(e) => handleInputChange(e)}
        onBlur={handleBlur}
      >
      </SearchInput>
    </SelectBox>
  );
};

export default SearchButton;


const SelectBox = styled.div`
  display: flex;
  margin-left: 10px;
  width: 200px;
  height: 30px;
  padding: 8px;
  border-radius: 5px;
  background-color: #ffffff;
  align-self: center;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: default;
  content: "⌵";
  
  border: 1px solid transparent; /* 초기 테두리는 투명 */
  transition: border-color ; /* 테두리 색상 변화에 대한 전환 효과 */
  &:focus-within {
    border-color: gray; /* 포커스가 SelectBox 안에 있는 요소에 적용됐을 때 테두리 색상 변경 */
  }

  &::before {
  

    // top: ${props => props.show ? '14px' : '6px'};

  }


  &:hover {
    border-color: gray; /* 마우스를 올릴 때 테두리 색상 변경 */
  }

  
`;



const SearchInput = styled.input`
  width: 160px;
  border-width: 0px;
  border-color: white;
  font-size: 20px;


  &:focus {
    outline: none; /* 포커스 효과 제거 */
  }

  &::placeholder {
    color: gray; /* placeholder의 글자 색을 변경 */
  }
`

const GlassContainer = styled.div`
  width: 25px;
  margin-top: 7px;
  margin-left: 3px;
`