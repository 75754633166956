import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux"

export default function Menu() {
  let redux = useSelector((state) => state)
  const [locationId, setLocationId] = useState(redux.locationId);
  const [currentCategoryId, setCurrentCategoryId ] = useState(1);
  
  useEffect(() => {
    // locationId가 변경될 때마다 실행되는 코드
    let updatedlocationId = redux.locationId
    setLocationId(updatedlocationId)

    let url =  `${process.env.REACT_APP_API_SERVER_URL}/api/v1/product/${updatedlocationId}/category/${currentCategoryId}`
    // 다른 작업을 수행하거나 함수 호출 등
    if (url !== "") {
      axios
        .get(url)
        .then((response) => {
          console.log("Response : ", response.data);
          setDataList(response.data.data);
        })
        .catch((error) => console.error(`Error!!!: ${error}`));
    }
  }, [redux.locationId]);
  
  const [isButton1Clicked, setButton1Clicked] = useState(true);
  const [isButton2Clicked, setButton2Clicked] = useState(false);
  const [isButton3Clicked, setButton3Clicked] = useState(false);
  const [isButton4Clicked, setButton4Clicked] = useState(false);

  const [apiUrl, setApiUrl] = useState(
    `${process.env.REACT_APP_API_SERVER_URL}/api/v1/product/${locationId}/category/1`
  ); // 초기값 설정

  const [dataList, setDataList] = useState([]);

  const handleButton1Click = () => {
    if (!isButton1Clicked) {
      console.log("locationId : 22",locationId);
      setButton1Clicked(true);
      setButton2Clicked(false);
      setButton3Clicked(false);
      setButton4Clicked(false);
      setCurrentCategoryId(1)
      setApiUrl(
        `${process.env.REACT_APP_API_SERVER_URL}/api/v1/product/${locationId}/category/1`
      );
    }
  };

  const handleButton2Click = () => {
    if (!isButton2Clicked) {
      setButton1Clicked(false);
      setButton2Clicked(true);
      setButton3Clicked(false);
      setButton4Clicked(false);
      setCurrentCategoryId(2)
      setApiUrl(
        `${process.env.REACT_APP_API_SERVER_URL}/api/v1/product/${locationId}/category/2`
      );
    }
  };

  const handleButton3Click = () => {
    if (!isButton3Clicked) {
      setButton1Clicked(false);
      setButton2Clicked(false);
      setButton3Clicked(true);
      setButton4Clicked(false);
      setCurrentCategoryId(3)
      setApiUrl(
        `${process.env.REACT_APP_API_SERVER_URL}/api/v1/product/${locationId}/category/3`
      );
    }
  };

  const handleButton4Click = () => {
    if (!isButton4Clicked) {
      setButton1Clicked(false);
      setButton2Clicked(false);
      setButton3Clicked(false);
      setButton4Clicked(true);
      setCurrentCategoryId(4)
      setApiUrl(
        `${process.env.REACT_APP_API_SERVER_URL}/api/v1/product/${locationId}/category/4`
      );
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const updatedList = Array.from(dataList);
    const [draggedItem] = updatedList.splice(source.index, 1);
    updatedList.splice(destination.index, 0, draggedItem);

    setDataList(updatedList);
  };

  useEffect(() => {
    if (apiUrl !== "") {
      axios
        .get(apiUrl)
        .then((response) => {
          console.log(response.data);
          setDataList(response.data.data);
        })
        .catch((error) => console.error(`Error!!!: ${error}`));
    }
  }, [apiUrl]);

  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "1168px" }}>
      <Container>
        <Button1 clicked={isButton1Clicked} onClick={handleButton1Click}>
          커피
        </Button1>
        <Button2 clicked={isButton2Clicked} onClick={handleButton2Click}>
          티&라떼
        </Button2>
        <Button3 clicked={isButton3Clicked} onClick={handleButton3Click}>
          에이드
        </Button3>
        <Button4 clicked={isButton4Clicked} onClick={handleButton4Click}>
          아이스크림
        </Button4>
        <Button5 style={{ marginLeft: "170px" }}>순서편집</Button5>
        <Button5 onClick={() => navigate("/addmenu")}>메뉴추가</Button5>
      </Container>

      <Container
        style={{
          overflowY: "auto",
          width: "1168px",
          maxHeight: "760px",
          scrollbarWidth: "thin",
          margin: "25px 0 0 15px",
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <div
            style={{
              overflowY: "auto",
              maxHeight: "736px",
            }}
          >
            <Droppable droppableId="menu-grid">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "10px",
                  }}
                >
                  {dataList.map((item, index) => (
                    <Draggable
                      key={item.productId}
                      draggableId={`item-${item.productId}`}
                      index={index}
                    >
                      {(provided) => (
                        <button
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            background: "none",
                            border: "1px solid #D9D9D9",
                            cursor: "pointer",
                            padding: "8px",
                            borderRadius: "8px",
                            width: "270px",
                            ...provided.draggableProps.style,
                          }}
                          onClick={() =>
                            navigate(`/editmenu/${item.productId}`)
                          }
                        >
                          <img
                            src={item.url}
                            alt={item.name}
                            style={{ width: "100%" }}
                          />
                          <Box>
                            {" "}
                            <p style={{ fontSize: "22px" }}>{item.name}</p>
                          </Box>
                        </button>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </Container>
    </div>
  );
}

export const Box = styled.div`
  background: #d9d9d9;
  font-size: 24px;
  width: 254px;
  height: 44px;
  padding: 0;
  margin-top: 10px;
  border-radius: 4px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button1 = styled.button`
  background-color: ${(props) => (props.clicked ? "#CCCCCC" : "#E6E6E6")};
  font-size: 24px;
  color: ${(props) => (props.clicked ? "black" : "#737373")};
  height: 48px;
  width: 140px;
  margin: 48px 8px 0 54px;
  border: none;
  transition: 0.5s;
  border-radius: 8px;
  font-weight: ${(props) => (props.clicked ? "bold" : "normal")};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;
const Button2 = styled.button`
  background-color: ${(props) => (props.clicked ? "#CCCCCC" : "#E6E6E6")};
  font-size: 24px;
  color: ${(props) => (props.clicked ? "black" : "#737373")};
  height: 48px;
  width: 140px;
  margin: 48px 8px 0 0;
  border: none;
  transition: 0.5s;
  border-radius: 8px;
  font-weight: ${(props) => (props.clicked ? "bold" : "normal")};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Button3 = styled.button`
  background-color: ${(props) => (props.clicked ? "#CCCCCC" : "#E6E6E6")};
  font-size: 24px;
  color: ${(props) => (props.clicked ? "black" : "#737373")};
  height: 48px;
  width: 140px;
  margin: 48px 8px 0 0;
  border: none;
  transition: 0.5s;
  border-radius: 8px;
  font-weight: ${(props) => (props.clicked ? "bold" : "normal")};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Button4 = styled.button`
  background-color: ${(props) => (props.clicked ? "#CCCCCC" : "#E6E6E6")};
  font-size: 24px;
  color: ${(props) => (props.clicked ? "black" : "#737373")};
  height: 48px;
  width: 140px;
  margin: 48px 0 0 0;
  border: none;
  transition: 0.5s;
  border-radius: 8px;
  font-weight: ${(props) => (props.clicked ? "bold" : "normal")};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Button5 = styled.button`
  background-color: #e6e6e6;
  font-size: 24px;
  color: black;
  height: 48px;
  width: 180px;
  margin: 48px 10px 0 0px;
  border: none;
  transition: 0.5s;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
  }
`;