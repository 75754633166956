import { useState } from "react";
import styled from "styled-components";
import React from "react";
import Tab1 from "./Tab1";
import Tab2 from "./Tab2";
import Tab3 from "./Tab3";

export const TabLayout = ({ socket, setMessage, message, teadata }) => {
  const [currentTab, clickTab] = useState(0);

  const menuArr = [
    { name: "수전", content: <Tab1 socket={socket} setMessage={setMessage} message={message} />, width: "250px" },
    { name: "CUP / ICE CREAM / TEA", content: <Tab2 socket={socket} setMessage={setMessage} message={message} teadata={teadata}/>, width: "400px" },
    { name: "TOPPING / DRIZZLE / GRIPPER", content: <Tab3 socket={socket} setMessage={setMessage} message={message}/>, width: "400px" },
  ];

  const selectMenuHandler = (index) => {
    console.log('test')
    clickTab(index);
  };

  return (
    <>
      <div style={{ width: "1230px" }}>
        <TabMenu>
          {menuArr.map((tap, index) => {
            return (
              <div
                key={index}
                className={currentTab === index ? "submenu focused" : "submenu"}
                onClick={() => selectMenuHandler(index)}
                style={{ width: tap.width }}
              >
                {tap.name}
              </div>
            );
          })}
        </TabMenu>
        <Box></Box>
        <div style={{ width: "1050px" }}>
          <div>{menuArr[currentTab].content}</div>
        </div>
      </div>
    </>
  );
};

export const TabMenu = styled.div`
  background-color: #fdfdfd;
  display: flex;
  list-style: none;
  cursor: pointer;
  margin-right: 11.5rem;
  top: 1rem;
  height: 65px;

  .submenu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    font-size: 24px;
    font-weight: bold;
    color: #d9d9d9;
    border-radius: 10px 10px 0px 0px;

    padding-left: 0.32rem;
    padding-right: 0.32rem;
    transition: 0.5s;

    &::first-child {
      border-right: 0.02rem solid black;
    }

    &::last-child {
    }
  }

  .focused {
    background-color: #d9d9d9;
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`;

const Desc = styled.div`
  width: 1046px;
  height: 811px;
  background-color: #444444;
`;

const Box = styled.div`
  background-color: #3d3d3d;
  opacity: 0.5;
  width: 1046px;
  height: 4px;
`;