import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Swal from "sweetalert2";

import ice8 from "../../assets/CIT_CUP/ice8.png";
import ice9 from "../../assets/CIT_CUP/ice8.png";
import ice14 from "../../assets/CIT_CUP/ice14.png";
import ice20 from "../../assets/CIT_CUP/ice20.png";
import hot4 from "../../assets/CIT_CUP/hot4.png";
import hot10 from "../../assets/CIT_CUP/hot10.png";
import hot10_C from "../../assets/CIT_CUP/hot10_C.png";
import hot16 from "../../assets/CIT_CUP/hot16.png";
import hot16_C from "../../assets/CIT_CUP/hot16_C.png";

function GridButtonIce({ onClick, valueObject }) {
  return (
    <ButtonIceCup
      imageUrl={valueObject.image} // Add this line
      onClick={() => onClick(valueObject)}
    >
      {valueObject.name}
    </ButtonIceCup>
  );
}

function GridButtonHot({ onClick, valueObject }) {
  return (
    <ButtonHotCup
      imageUrl={valueObject.image} // Add this line
      onClick={() => onClick(valueObject)}
    >
      {valueObject.name}
    </ButtonHotCup>
  );
}

function Tab2({ socket, teadata }) { 
  const buttonValues1 = [
    [
      {
        id: "Ice20",
        image: ice20,
        message: "20 온즈 아이스컵 추출중...",
        data:{
          "function":"clean",
          "value":{ button: "cup_20_on", value: 1 }
          }
      },
      {
        id: "Ice9",
        image: ice9,
        message: "9 온즈 아이스컵 추출중...",
        data: {
          "function":"clean",
          "value":{ button: "cup_9_on", value: 1 }
          }
      },
      {
        id: "Ice14",
        image: ice14,
        message: "14온즈 아이스컵 추출중...",
        data: {
          "function":"clean",
          "value":{ button: "cup_14_on", value: 1 }
          }
      },
      {
        id: "Ice8",
        image: ice8,
        message: "8온즈 아이스컵 추출중...",
        data: {
          "function":"clean",
          "value":{ button: "cup_8_on", value: 1 }
          }
      },
    ],
    ];
    const buttonValues2 = [
      [
        {
          id: "Hot16_C",
          image: hot16_C,
          message: "16온즈 핫컵 컬러 추출중...",
          data: {
            "function":"clean",
            "value":{ button: "cup_16Left_on", value: 1 }
            }
        },
        {
          id: "Hot16",
          image: hot16,
          message: "16온즈 핫컵 추출중...",
          data: {
            "function":"clean",
            "value":{ button: "cup_16Right_on", value: 1 }
            }
        },
        {
          id: "Hot12_C",
          image: hot10_C,
          message: "10온즈 핫컵 컬러 추출중...",
          data: {
            "function":"clean",
            "value":{ button: "cup_10Left_on", value: 1 }
            }
        },
        {
          id: "Hot12",
          image: hot10,
          message: "10온즈 핫컵 추출중...",
          data: {
            "function":"clean",
            "value":{ button: "cup_10Right_on", value: 1 }
            }
        },
        {
          id: "Hot4",
          image: hot4,
          message: "4온즈 핫컵 추출중...",
          data: {
            "function":"clean",
            "value":{ button: "cup_4_on", value: 1 }
            }
        },
      ],
    ]

  const [clickedButton, setClickedButton] = useState(null);
  const [clickedButton2, setClickedButton2] = useState(false);
  const [clickedButton3, setClickedButton3] = useState(false);
  const [clickedButton4, setClickedButton4] = useState(false);
  const [message, setMessage] = useState("");

  const [tea1Message, setTea1Message] = useState(teadata.tea1);
  const [tea2Message, setTea2Message] = useState(teadata.tea2);
  const [tea3Message, setTea3Message] = useState(teadata.tea3);
  const [tea4Message, setTea4Message] = useState(teadata.tea4);
  const [tea5Message, setTea5Message] = useState(teadata.tea5);
  const [tea6Message, setTea6Message] = useState(teadata.tea6);

  const [inputValue1, setInputValue1] = useState(0);
  const [inputValue2, setInputValue2] = useState(0);
  const [inputValue3, setInputValue3] = useState(0);
  const [inputValue4, setInputValue4] = useState(0);
  const [inputValue5, setInputValue5] = useState(0);
  const [inputValue6, setInputValue6] = useState(0);

  const handleButtonClick = (valueObject) => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    socket.send(JSON.stringify(valueObject.data));
    setMessage(valueObject.message);
  };

  const handleICEClick = () => {
    Swal.fire({
          title: "아이스크림을 한 번 </br> 뽑으시겠습니까?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
               const data1 = {
          "function":"clean",
          "value": { button: "icecream_1dose", value: 1 }
          };
      
            if (!socket) {
              console.log("WebSocket이 연결되지 않았습니다.");
    
              return;
            }
      
            socket.send(JSON.stringify(data1));
            setMessage("아이스크림 추출중...");
          }
        });
      };

  const handleOPENClick = () => {
    Swal.fire({
          title: "아이스크림을 뽑으시겠습니까?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
               const data1 = {
          "function":"clean",
          "value": { button: "icecream_on", value: 1 }
          };
      
            if (!socket) {
              console.log("WebSocket이 연결되지 않았습니다.");
    
              return;
            }
      
            socket.send(JSON.stringify(data1));
         setMessage("OPEN...");
          }
        });
      };

  const handleCLOSEClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value": { button: "icecream_off", value: 1 }
      };

    socket.send(JSON.stringify(data1));
    setMessage("CLOSE...");
  };

  const Tea1Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }
  
    const inputValue = inputValue1 || 8; // setInputValue1이 존재하지 않을 경우 기본값 8 
  
    const data1 = {
      "function": "clean",
      "value": {
        button: "tea1_reset",
        value: inputValue,
      }
    };
  
    socket.send(JSON.stringify(data1));
    setMessage("tea1_reset...");
    console.log('보낸 데이터 :', data1);
  
    socket.onmessage = (event) => {
      const tea1Message = event.data;
      console.log("서버에서 받은 메시지:", tea1Message);
      setTea1Message(tea1Message); // 서버에서 받은 메시지로 상태를 업데이트
    };
  };
  
  

  const Tea2Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }
  
    const inputValue = inputValue2 || 8; // setInputValue1이 존재하지 않을 경우 기본값 8 
  
    const data1 = {
      "function": "clean",
      "value": {
        button: "tea2_reset",
        value: inputValue,
      }
    };

    socket.send(JSON.stringify(data1));
    setMessage("tea2_reset...");    
    console.log(data1);


    socket.onmessage = (event) => {
      const tea2Message = event.data;
      console.log("서버에서 받은 메시지:", tea2Message);
      setTea2Message(tea2Message); // 서버에서 받은 메시지로 상태를 업데이트
    };
  };

  const Tea3Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const inputValue = inputValue3 || 8; // setInputValue1이 존재하지 않을 경우 기본값 8 
  
    const data1 = {
      "function": "clean",
      "value": {
        button: "tea3_reset",
        value: inputValue,
      }
    };

    socket.send(JSON.stringify(data1));
    setMessage("tea3_reset...");

    
    socket.onmessage = (event) => {
      const tea3Message = event.data;
      console.log("서버에서 받은 메시지:", tea3Message);
      setTea3Message(tea3Message); // 서버에서 받은 메시지로 상태를 업데이트
    };
  };

  const Tea4Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const inputValue = inputValue4 || 8; // setInputValue1이 존재하지 않을 경우 기본값 8 
  
    const data1 = {
      "function": "clean",
      "value": {
        button: "tea4_reset",
        value: inputValue,
      }
    };

    socket.send(JSON.stringify(data1));
    setMessage("tea4_reset...");

    
    socket.onmessage = (event) => {
      const tea4Message = event.data;
      console.log("서버에서 받은 메시지:", tea4Message);
      setTea4Message(tea4Message); // 서버에서 받은 메시지로 상태를 업데이트
    };
  };
  
  const Tea5Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const inputValue = inputValue5 || 8; // setInputValue1이 존재하지 않을 경우 기본값 8 
  
    const data1 = {
      "function": "clean",
      "value": {
        button: "tea5_reset",
        value: inputValue,
      }
    };

    socket.send(JSON.stringify(data1));
    setMessage("tea5_reset...");

    
    socket.onmessage = (event) => {
      const tea5Message = event.data;
      console.log("서버에서 받은 메시지:", tea5Message);
      setTea5Message(tea5Message); // 서버에서 받은 메시지로 상태를 업데이트
    };
  };

  const Tea6Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const inputValue = inputValue6 || 8; // setInputValue1이 존재하지 않을 경우 기본값 8 
  
    const data1 = {
      "function": "clean",
      "value": {
        button: "tea6_reset",
        value: inputValue,
      }
    };

    socket.send(JSON.stringify(data1));
    setMessage("tea6_reset...");

    socket.onmessage = (event) => {
      const tea6Message = event.data;
      console.log("서버에서 받은 메시지:", tea6Message);
      setTea6Message(tea6Message); // 서버에서 받은 메시지로 상태를 업데이트
    };
  };

  const createGridHot = (buttonValues) =>
    buttonValues.map((rowValueArray, rowIndex) => (
      <div key={rowIndex}>
        {rowValueArray.map((valueObject, colIndex) => (
          <GridButtonHot
            key={colIndex}
            value={valueObject.image}
            valueObject={valueObject}
            onClick={handleButtonClick}
          />
        ))}
      </div>
    ));
    
    const createGridIce = (buttonValues) =>
    buttonValues.map((rowValueArray, rowIndex) => (
      <div key={rowIndex}>
        {rowValueArray.map((valueObject, colIndex) => (
          <GridButtonIce
            key={colIndex}
            value={valueObject.image}
            valueObject={valueObject}
            onClick={handleButtonClick}
          />
        ))}
      </div>
    ));

  return (
    <Box1>
      <BoxCategory> CUP </BoxCategory>
      <div style={{ display: "flex", marginTop: "6px", flexDirection : "column"}}>
        <div>{createGridIce(buttonValues1)}</div>
        <div>{createGridHot(buttonValues2)}</div>
      </div>
      <BoxCategory style={{ marginTop: "10px" }}> ICE CREAM </BoxCategory>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Button2
          style={{ margin: "10px 10px 0 -10px" }}
          clicked={clickedButton2}
          onClick={handleICEClick}
        >
          아이스크림 뽑기
        </Button2>
        <Button3
          style={{ margin: "10px 10px 0 0" }}
          clicked={clickedButton3}
          onClick={handleOPENClick}
        >
          OPEN
        </Button3>
        <Button3
          style={{ margin: "10px 0 0 0" }}
          clicked={clickedButton3}
          onClick={handleCLOSEClick}
        >
          CLOSE
        </Button3>
      </div>

      <BoxCategory style={{ marginTop: "16px" }}> TEA </BoxCategory>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: '7px', }}>
  <LabelTea style={{ marginLeft: "0px", marginRight: "10px" }}>{tea1Message}</LabelTea>
  <LabelTea>/ 8</LabelTea>

  <LabelTea style={{ marginLeft: "100px", marginRight: "10px" }}>{tea2Message}</LabelTea>
  <LabelTea>/ 8</LabelTea>

  <LabelTea style={{ marginLeft: "100px", marginRight: "10px" }}>{tea3Message}</LabelTea>
  <LabelTea>/ 8</LabelTea>

  <LabelTea style={{ marginLeft: "100px", marginRight: "10px" }}>{tea4Message}</LabelTea>
  <LabelTea>/ 8</LabelTea>

  <LabelTea style={{ marginLeft: "100px", marginRight: "10px" }}>{tea5Message}</LabelTea>
  <LabelTea>/ 8</LabelTea>

  <LabelTea style={{ marginLeft: "105px", marginRight: "10px" }}>{tea6Message}</LabelTea>
  <LabelTea>/ 8</LabelTea>
  </div>

      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
        <ButtonTea
          style={{ margin: "10px 10px 10px 0px" }}
          onClick={Tea1Click}
        >
          오렌지 자몽 <br/> 블랙티
        </ButtonTea>
        <ButtonTea
          style={{ margin: "10px 10px 10px 0px" }}
          onClick={Tea2Click}
        >
          딸기 피치  <br/> 루이보스티
        </ButtonTea>
        <ButtonTea 
        style={{ margin: "10px 10px 10px 0px" }} 
        onClick={Tea3Click}
        >
           핑크 리치  <br/> 캐모마일티
        </ButtonTea>
        <ButtonTea 
        style={{ margin: "10px 10px 10px 0px" }} 
        onClick={Tea4Click}
        >
          베리썸  <br/> 히비스커스티
        </ButtonTea>
        <ButtonTea 
        style={{ margin: "10px 10px 10px 0px" }} 
        onClick={Tea5Click}
        >
          제주 청귤  <br/> 캐모마일티
        </ButtonTea>
        <ButtonTea 
        style={{ margin: "10px 10px 10px 0px" }} 
        onClick={Tea6Click}
        >
          레  몬 <br/> 얼그레이티
        </ButtonTea>
    </div>


    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
    <InputTea style={{ marginTop: "0px",  marginRight: "10px"  }} 
          type="number"
          min="1"
          max="8"
    

          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value < 1 || value > 8) {
              alert("1~8 사이의 정수만 입력해 주세요..! ( •̀ω•́ )و✧");
              e.target.value = "";
              return;
            }
            setInputValue1(value);
          }}
        />
  <LabelTeaQ>개</LabelTeaQ>

    <InputTea style={{ marginTop: "0px",  marginRight: "10px", marginLeft: "60px"  }} 
          type="number"
          min="1"
          max="8"
  

          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value < 1 || value > 8) {
              alert("1~8 사이의 정수만 입력해 주세요..! ( •̀ω•́ )و✧");
              e.target.value = "";
              return;
            }
            setInputValue2(value);
          }}
        />
  <LabelTeaQ>개</LabelTeaQ>
  
    <InputTea style={{ marginTop: "0px",  marginRight: "10px", marginLeft: "60px"  }} 
          type="number"
          min="1"
          max="8"

          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value < 1 || value > 8) {
              alert("1~8 사이의 정수만 입력해 주세요..! ( •̀ω•́ )و✧");
              e.target.value = "";
              return;
            }
            setInputValue3(value);
          }}
        />
  <LabelTeaQ>개</LabelTeaQ>

    <InputTea style={{ marginTop: "0px",  marginRight: "10px", marginLeft: "60px"  }} 
          type="number"
          min="1"
          max="8"
 
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value < 1 || value > 8) {
              alert("1~8 사이의 정수만 입력해 주세요..! ( •̀ω•́ )و✧");
              e.target.value = "";
              return;
            }
            setInputValue4(value);
          }}
        />
  <LabelTeaQ>개</LabelTeaQ>

    <InputTea style={{ marginTop: "0px",  marginRight: "10px", marginLeft: "60px"  }} 
          type="number"
          min="1"
          max="8"


          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value < 1 || value > 8) {
              alert("1~8 사이의 정수만 입력해 주세요..! ( •̀ω•́ )و✧");
              e.target.value = "";
              return;
            }
            setInputValue5(value);
          }}
        />
  <LabelTeaQ>개</LabelTeaQ>

    <InputTea style={{ marginTop: "0px",  marginRight: "10px", marginLeft: "60px"  }} 
          type="number"
          min="1"
          max="8"
      

          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value < 1 || value > 8) {
              alert("1~8 사이의 정수만 입력해 주세요..! ( •̀ω•́ )و✧");
              e.target.value = "";
              return;
            }
            setInputValue6(value);
          }}
        />
          <LabelTeaQ>개</LabelTeaQ>

  </div>

  
    </Box1>
  );
}

export default Tab2;

const InputTea = styled.input`
  font-size: 26px;
  background-color: white;
  width: 60px;
  height: 50px;
  padding-left: 15px;
  border-radius: 8px;
  margin: 5px;

  border: none;
`;

const LabelTeaQ = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 24px;
margin-top: 10px;

`;
const LabelTea = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 28px;

`;

const Box1 = styled.div`
  display: flex;
  align-items: center;
  width: 1046px;
  height: 730px;
  background: #f0f0f0;
  flex-direction: column;
`;

const BoxCategory = styled.div`
  width: 990px;
  height: 32px;
  background: linear-gradient(
    90deg,
    #d9d9d9 1.57%,
    rgba(217, 217, 217, 0) 100%
  );
  opacity: 0.8;
  border-radius: 7px;
  color: white;
  font-size: 22px;
  font-weight: bold;
  margin-top: 28px;
  padding-left: 10px;
`;

const ButtonIceCup = styled.button`
  font-size: 28px;
  color: black;
  margin: 0 10px 10px 0;
  width: 240px;
  height: 110px;
  border-radius: 8px;
  transition: 0.5s;
  background-color: #ffffff;
  border: none;
  font-weight: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  background-image: url(${(props) => props.imageUrl});
  background-size: cover;

  &:hover {
    cursor: pointer;
    border: 1px solid #476FFF;
  }
`;

const ButtonHotCup = styled.button`
  font-size: 28px;
  color: black;
  margin: 0 10px 10px 0;
  width: 190px;
  height: 110px;
  border-radius: 8px;
  transition: 0.5s;
  background-color: #ffffff;
  border: none;
  font-weight: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  background-image: url(${(props) => props.imageUrl});
  background-size: cover;

  &:hover {
    cursor: pointer;
    border: 1px solid #f84d27;
  }
`;
const Button2 = styled.button`
  width: 490px;
  height: 90px;
  background-color: #ffffff;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 28px;
  color: black;
  transition: 0.5s;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: #fff8f6;
    border: 1px solid #f84d27;
  }
`;

const Button4 = styled.button`
  width: 240px;
  height: 110px;
  background-color: #f3f3f3;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;

const Button3 = styled.button`
  width: 240px;
  height: 90px;
  background-color: #ffffff;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px; 
  font-size: 24px;
  color: black;
  transition: 0.5s;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: #fff8f6;
    border: 1px solid #f84d27;
  }
`;

const ButtonTea = styled.button`
  width: 156px;
  height: 90px;
  background-color: #ffffff;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 20px;
  color: black;
  transition: 0.5s;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: #fff8f6;
    border: 1px solid #f84d27;
  }
`;