import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';



const DeleteListModal = ({open, deleteList, handleChangeDeleteModal }) => {
    if(!open){
        return null;
      }


    const handleDelete = () => {
        console.log("deleteList : ", deleteList)

        
        console.log([...deleteList])

        const data1 = {
            "deleteList" : [...deleteList]
        }
        axios.delete(`${process.env.REACT_APP_API_SERVER_URL}/api/v1/inventory/list`, {data: data1,     headers: {
            'Content-Type': 'application/json'
        }}) 
        .then((response) => {
          console.log("삭제 성공!");
          window.location.reload();
        })
        .catch((error) => {
          console.log("삭제 실패:", error);
        });
    }

    const handleOpenModal = () => {
        handleChangeDeleteModal(false)
    }

  return (
    <>
      <EditModal>
        <div style={{ width: '100%', height: '80px', display: 'flex', justifyContent: 'left', marginLeft: '20px', marginTop: '20px', marginBottom: '-10px', fontSize: '35px', fontWeight: 'bold' }}>Delete</div>        
        <div style={{marginLeft:'20px'}}>정말 삭제하시겠습니까?</div>
        <div style={{display: 'flex', justifyContent: 'flex-start', height: "50px", marginTop:"30px"}}>
          <CancelButton onClick={handleOpenModal}>취소</CancelButton>
          <ConfirmButton onClick={handleDelete}>확인</ConfirmButton>
        </div>
      </EditModal>
    </>
  );
};

export default DeleteListModal;





const EditModal = styled.div`    
  position: absolute;  
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 100px;

  width: 380px;
  height: 200px;
  background: white;
  border-radius: 5px;
//   position: fixed;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);


  z-index: 5;
  left: 550px;;

  animation: modalShow 0.2s linear;
  @keyframes modalShow {
    from {
      transform: scale(0);

   }
   to {
       transform: scale(1);   

    }
    }
`;

const InputContainer = styled.div`
display: flex;
background: white;
height: 40px;
margin-bottom: 25px;


`


const CancelButton = styled.button`
  display: block;
  justify-content: center;
  font-size: 19px;
  margin-top: 3px;
  text-align: center;
  color: gray;
  width: 48%;
  margin-left: 5px;
  border: 0px;
  border-radius: 10px;
  background: white;
  font-weight: bold;

&: hover {
  background: #F5F5F5;
//   font-weight: bold;
}
`

const ConfirmButton = styled.button`
  display: block;
  justify-content: center;
  font-size: 19px;
  margin-top: 3px;
  text-align: center;
  color: white;
  width: 48%;
  margin-left: 5px;
  border: 0px;
  border-radius: 10px;
  background: #ff5630;
  font-weight: bold;

&: hover {
    background: #cc4125; /* hover 시 살짝 어두운 색상으로 변경합니다. */
    // font-weight: bold;
}
`