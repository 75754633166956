// App.js
import React from 'react';
import styled from "styled-components";
import { Route, Link, useLocation, Routes, Navigate  } from 'react-router-dom';
import { ButtonReDoProvider } from './ButtonReDoContext';
import useScrollRestoration from './useScrollRestoration';

import LogIn from "./pages/LogIn/LogIn";
import CleaningMode from "./pages/CleaningMode/CleaningMode";
import MenuManagement from "./pages/MenuManagement/MenuManagement";
import AddMenu from "./pages/AddMenu/AddMenu";
import EditMenu from "./pages/EditMenu/EditMenu";
import AppControl from "./pages/DBControl/AppControl";
import DynamodbControl from "./pages/DBControl/DynamodbControl";
import Inventory from './pages/InventoryManagement/IngredientInventory';
import InventoryDashboard from "./pages/InventoryDashboard/InventoryDashboard";
import InventoryLog from './pages/InventoryManagement/InventoryLog';




import Sidebar from "./components/Sidebar/Sidebar";
import PrivateRoute from "./pages/auth/PrivateRoute";




function App() {
  useScrollRestoration();

  const location = useLocation(); // 현재 위치 정보 알려줌..

  const redirectToLogin = () => {
    return <Navigate to="/" replace />;
  };

  return (
    <PageLayout>
      <ButtonReDoProvider>
        {location.pathname !== '/' && <Sidebar />} {/* 로그인 페이지가 아닐 때만 Sidebar 렌더링 */}
        <Content>
          <Routes>
          <Route path="/appcontrol" element={<AppControl />} />

            <Route path="/" element={<LogIn />} />

             <Route element={<PrivateRoute/>}>
              <Route path="/cleaningmode" element={<CleaningMode />} />
              <Route path="/menumanagement" element={<MenuManagement />} />
              <Route path="/addmenu" element={<AddMenu />} />
              <Route path="/editmenu/:productId" element={<EditMenu />} />
              {/* <Route path="/appcontrol" element={<AppControl />} /> */}
              <Route path="/dynamodbcontrol" element={<DynamodbControl />} />
              <Route path="/inventory" element={<Inventory/>} />
              <Route path="/inventory/log" element={<InventoryLog/>} />

              <Route path="/inventorydashboard" element={<InventoryDashboard />} />
              <Route path="/*" element={redirectToLogin} /> {/* 이외의 경로는 로그인 페이지로 이동 */}
             </Route> 
          </Routes>
        </Content>
      </ButtonReDoProvider>
    </PageLayout>
  );
}

export default App;


const PageLayout = styled.div`
  display: flex; 
`;

const Content = styled.div`
  flex-grow: 1; 
`;
