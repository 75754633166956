import React, { useEffect, useState } from "react";
import { ButtonReDoProvider } from '../../ButtonReDoContext';

import styled from "styled-components";
import D_Tab1 from "./D_Tab1";
import D_Tab2 from "./D_Tab2";
import D_Tab3 from "./D_Tab3";


export default function App() {
  const [socket, setSocket] = useState();
  const [message, setMessage] = useState("");
  const [currentTab, clickTab] = useState(0);

  useEffect(() => {
    const socketInstance = new WebSocket("ws://192.168.0.27:12345");

    socketInstance.onopen = function (event) {
      console.log("WebSocket Dashboard 연결 성공");
      setSocket(socketInstance);

      // 30초마다 메시지 전송
      setInterval(() => {
        if (socketInstance.readyState === WebSocket.OPEN) {
          socketInstance.send("ping!");
          console.log("서버에 메시지 전송");
        }
      }, 30000);
    };

    socketInstance.onmessage = function (event) {
      console.log("서버에서 받은 데이터:", event.data);
      // 받은 데이터를 로그창에 출력하거나 원하는 처리를 수행
    };
  
    // 컴포넌트 언마운트 시 소켓 연결 종료
    return () => {
      socketInstance.close();
      console.log("WebSocket cleaning mode 연결 종료");
    };
  }, []);

  useEffect(() => {
    return () => { 
      if (socket !== undefined) {
        socket.close();
        console.log("WebSocket 연결 닫힘");
      }
    };
  }, [socket]);
  

  const menuArr = [
    { name: "Inventory Category1", content: <D_Tab1 socket={socket} setMessage={setMessage} message={message} />, width: "420px" },
    { name: "Inventory Category2", content: <D_Tab2 socket={socket} setMessage={setMessage} message={message}/>, width: "420px" },
    { name: "Inventory Category3", content: <D_Tab3 socket={socket} setMessage={setMessage} message={message}/>, width: "420px" },
  ];

  const selectMenuHandler = (index) => {
    console.log('Tab change')
    clickTab(index);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "800px",
        width: "1260px",
        marginTop: "2px",
      }}
    >

      <div style={{ width: "1260px" }}>
        <TabMenu>
          {menuArr.map((tap, index) => {
            return (
              <div
                key={index}
                className={currentTab === index ? "submenu focused" : "submenu"}
                onClick={() => selectMenuHandler(index)}
                style={{ width: tap.width }}
              >
                {tap.name}
              </div>
            );
          })}
        </TabMenu>
        <Box></Box>
        <div style={{ width: "1050px" }}>
          <div>{menuArr[currentTab].content}</div>
        </div>
      </div>
    </div>
  );
}

export const TabMenu = styled.div`
  background-color: #fdfdfd;
  display: flex;
  list-style: none;
  cursor: pointer;
  margin-right: 11.5rem;
  top: 1rem;
  height: 65px;
  width: 1260px;

  .submenu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    font-size: 24px;
    font-weight: bold;
    color: #d9d9d9;
    border-radius: 10px 10px 0px 0px;

    padding-left: 0.32rem;
    padding-right: 0.32rem;
    transition: 0.5s;

    &::first-child {
      border-right: 0.02rem solid black;
    }

    &::last-child {
    }
  }

  .focused {
    background-color: #d9d9d9;
    color: black;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
`;

const Desc = styled.div`
  width: 1260px;
  height: 811px;
  background-color: #444444;
`;

const Box = styled.div`
  background-color: #3d3d3d;
  opacity: 0.5;
  width: 1260px;
  height: 4px;
`;