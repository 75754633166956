import { configureStore, createSlice } from "@reduxjs/toolkit";


let locationId = createSlice({
    name: 'locationId',
    initialState: "1",
    reducers: {
        changeLocationId(state, action) {
            return action.payload; // 새로운 locationId 값을 반환합니다.

        }

    }
})

export let { changeLocationId } = locationId.actions


export default configureStore({
    reducer: {
        locationId: locationId.reducer
    }
})