import React, { useEffect, useState } from "react";
import { ButtonReDoProvider } from '../../ButtonReDoContext';
import axios from "axios";
import styled from "styled-components";

export default function App() {
  const [isUnderMaintenance, setIsUnderMaintenance] = useState(null);
  const [MenuData, setMenuData] = useState([]);

  useEffect(() => {

      axios
      .get('https://zrtnrs2578.execute-api.ap-northeast-2.amazonaws.com/beta/bty_menu_soldout_fn', {
      headers: {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin': '*', // 서버에서 허용할 도메인 설정
    },
    mode: 'cors', // CORS 요청 설정
      })

      .then((response) => {
        console.log(response.data);
        setMenuData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


  }, []);
  
  const handleButtonMenuClick = (categoryName, menuCd) => {
    const updatedMenuData = MenuData.map((category) => {
      if (category.name === categoryName) {
          
        const updatedItems = category.items.map((item) => {
          if (item.id === item.temperature[0].menuCd) {
            // 해당 객체를 복사하여 soldout 값을 반전시킨 새로운 객체 생성
            const updatedItem = {
              ...item,
              soldout: !item.soldout,
            };
            return updatedItem;
          }
          return item;
        });
        // 카테고리 객체를 복사하여 업데이트된 items 배열로 대체
        const updatedCategory = {
          ...category,
          items: updatedItems,
        };
        return updatedCategory;
      }
      return category;
    });
  
    setMenuData(updatedMenuData);
    console.log(updatedMenuData);
  };
  
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
   
      <LineRow> </LineRow>

    <Container
        style={{
          overflowY: "auto",
          width: "1100px",
          maxHeight: "520px",
          scrollbarWidth: "thin",
        }} >

      {MenuData.map((category) => (
        <BoxCategory style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} key={category.name}>
          <LabelCategory>{category.name}</LabelCategory>
          {/* items 배열을 순회하며 temperature의 nameKor 값을 출력 */}
          {category.items.map((item) => (

        <ButtonMenu
        key={item.menuCd}
        style={{ backgroundColor: item.soldout ? "#d9d9d9" : "#ffffff" }}
        onClick={() => handleButtonMenuClick(category.name, item.menuCd)}
      >
        {item.temperature[0].nameKor}
      </ButtonMenu>
      
        ))}
        </BoxCategory>
      ))}
      </Container>

    </div>
  );
}

const BoxCategory = styled.div`
font-style: normal;
font-weight: 600;
font-size: 20px;
margin: 0 0 0 20px;
flex-direction: column;

`;

const ButtonMenu = styled.button`
  font-size: 20px;
  color: black;
  height: 50px;
  border-radius: 8px;
  font-weight: normal;
  background-color: #d9d9d9;
  margin:10px;

  &:hover {
    cursor: pointer;
  }
`;

const LabelCategory = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 20px;
margin: 10px 0 0 50px;
`;

const LineRow = styled.div`
width: 1000px;
height: 2px;
background: #d9d9d9;
margin: 20px 0 0 30px;
`;


const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
