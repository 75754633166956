import React, { useState, useEffect } from "react";
import styled from "styled-components";

function Tab3({ socket }) { 

  const [clickedButton, setClickedButton] = useState(null);
  const [clickedButton2, setClickedButton2] = useState(false);
  const [clickedButton3, setClickedButton3] = useState(false);
  const [clickedButton4, setClickedButton4] = useState(false);
  const [message, setMessage] = useState("");

  const Topping1Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "topping1_on",
            value: 1,
          }
      };

    socket.send(JSON.stringify(data1));
    setMessage("오레오 추출중...");
  };

  const Topping2Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "topping2_on",
            value: 1,
          }
      };

    socket.send(JSON.stringify(data1));
    setMessage("로투스 추출중...");
  };

  const Topping3Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "topping3_on",
            value: 1,
          }
      };

    socket.send(JSON.stringify(data1));
    setMessage("topping3 추출중...");
  };

  const Topping4Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "topping4_on",
            value: 1,
          }
      };

    socket.send(JSON.stringify(data1));
    setMessage("topping4 추출중...");
  };

  const Topping5Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "topping5_on",
            value: 1,
          }
      };

    socket.send(JSON.stringify(data1));
    setMessage("topping5 추출중...");
  };

  const Topping6Click = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "topping6_on",
            value: 1,
          }
      };

    socket.send(JSON.stringify(data1));
    setMessage("topping6 추출중...");
  };

  const handleToOrigin1 = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "drizzle1_back",
            value: 1,
          }
      }

    socket.send(JSON.stringify(data1));
    setMessage("원점으로...");
  };

  const handleToOrigin2 = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "drizzle2_back",
            value: 1,
          }
      }

    socket.send(JSON.stringify(data1));
    setMessage("원점으로...");
  };

  const handleFstDraw1 = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "drizzle1_front",
            value: 1,
          }
      };

    socket.send(JSON.stringify(data1));
    setMessage("1회 뽑기...");
  };

  const handleFstDraw2 = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "drizzle2_front",
            value: 1,
          }
      };

    socket.send(JSON.stringify(data1));
    setMessage("1회 뽑기...");
  };

  const handleDrizzleStop1 = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "drizzle1_stop",
            value: 1,
          }
      };

    socket.send(JSON.stringify(data1));
    setMessage("멈추기...");
  };

  const handleDrizzleStop2 = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = {
      "function":"clean",
      "value":{
            button: "drizzle2_stop",
            value: 1,
          }
      };

    socket.send(JSON.stringify(data1));
    setMessage("멈추기...");
  };

  const handleMixieGripperOpenClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = { button: "mixie_gripper_open", value: 1 };

    socket.send(JSON.stringify(data1));
    setMessage("Mixie Gripper Open...");
  };

  const handleMixieGripperCloseClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = { button: "mixie_gripper_close", value: 1 };

    socket.send(JSON.stringify(data1));
    setMessage("Mixie Gripper Close...");
  };

  const handlePortyGripperOpenClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = { button: "porty_gripper_open", value: 1 };

    socket.send(JSON.stringify(data1));
    setMessage("Porty Gripper Open...");
  };

  const handlePortyGripperCloseClick = () => {
    if (!socket) {
      console.log("WebSocket이 연결되지 않았습니다.");
      return;
    }

    const data1 = { button: "porty_gripper_close", value: 1 };

    socket.send(JSON.stringify(data1));
    setMessage("Porty Gripper Close...");
  };

  return (
    <Box1>
      <BoxCategory style={{ marginTop: "16px" }}> TOPPING </BoxCategory>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <ButtonTopping
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={Topping1Click}
        >
          오레오
        </ButtonTopping>
        <ButtonTopping
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton4}
          onClick={Topping2Click}
        >
          로투스
        </ButtonTopping>
        <ButtonTopping 
        style={{ margin: "10px 10px 10px 0px" }} 
        clicked={clickedButton3}
        onClick={Topping3Click}
        >
          후르츠 씨리얼
        </ButtonTopping>
        <ButtonTopping 
        style={{ margin: "10px 10px 10px 0px" }} 
        clicked={clickedButton3}
        onClick={Topping4Click}
        >
          미니볼 씨리얼
        </ButtonTopping>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
      <ButtonTopping 
        style={{ margin: "0px 10px 10px 0px" }} 
        clicked={clickedButton3}
        onClick={Topping5Click}
        >
        레인보우 <br/> 스프링클
        </ButtonTopping>
        <ButtonTopping 
        style={{ margin: "0px 10px 10px 0px" }} 
        clicked={clickedButton3}
        onClick={Topping6Click}
        >
          초콜릿 컬
        </ButtonTopping>
        <Button4 style={{ margin: "0px 10px 10px 0px" }} clicked={clickedButton4}>
          ㅤ
        </Button4>
        <Button4 style={{ margin: "0px 10px 10px 0px" }} clicked={clickedButton4}>
          ㅤ
        </Button4>
      </div>
      <BoxCategory style={{ marginTop: "10px", marginBottom: "6px" }}> DRIZZLE </BoxCategory>

      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Box_DZ>초코<br/>드리즐</Box_DZ>
        <ButtonDriizzle
          style={{ margin: "10px 10px 0px 0px" }}
          clicked={clickedButton3}
          onClick={handleToOrigin1}
        >
          원점으로 
        </ButtonDriizzle>

        <ButtonDriizzle
          style={{ margin: "10px 10px 0px 0" }}
          clicked={clickedButton3}
          onClick={handleFstDraw1}
        > 
          1회 뽑기 
        </ButtonDriizzle>
        <ButtonDriizzle
          style={{ margin: "10px 10px 0px 0" }}
          clicked={clickedButton3}
          onClick={handleDrizzleStop1}
        >
          멈추기
        </ButtonDriizzle>
        <Button4 style={{ margin: "10px 10px 10px 0px", width: "212px", height: "90px" }} clicked={clickedButton4}>
          ㅤ
        </Button4>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Box_DZ>카라멜<br/>드리즐</Box_DZ>
        <ButtonDriizzle
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handleToOrigin2}
        >
          원점으로 
        </ButtonDriizzle>
        
        <ButtonDriizzle
          style={{ margin: "10px 10px 10px 0" }}
          clicked={clickedButton3}
          onClick={handleFstDraw2}
        >
          1회 뽑기 
        </ButtonDriizzle>
        <ButtonDriizzle
          style={{ margin: "10px 10px 10px 0" }}
          clicked={clickedButton3}
          onClick={handleDrizzleStop2}
        >
          멈추기 
        </ButtonDriizzle>
        <Button4 style={{ margin: "10px 10px 10px 0px", width: "212px", height: "90px"}} clicked={clickedButton4}>
          ㅤ
        </Button4>
      </div>
      <div>
      <BoxCategory style={{ marginTop: "10px", marginBottom: "6px" }}> GRIPPER </BoxCategory>

      <ButtonGripper
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handleMixieGripperOpenClick}
        >
          MIXIE GRIPPER OPEN
        </ButtonGripper>
      <ButtonGripper
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handleMixieGripperCloseClick}
        >
          MIXIE GRIPPER CLOSE
        </ButtonGripper>
        <ButtonGripper
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handlePortyGripperOpenClick}
        >
          PORTY GRIPPER OPEN
        </ButtonGripper>
        <ButtonGripper
          style={{ margin: "10px 10px 10px 0px" }}
          clicked={clickedButton3}
          onClick={handlePortyGripperCloseClick}
        >
          PORTY GRIPPER CLOSE
        </ButtonGripper>
      </div>

    </Box1>
  );
}

export default Tab3;

const Box1 = styled.div`
  display: flex;
  align-items: center;
  width: 1046px;
  height: 730px;
  background: #f0f0f0;
  flex-direction: column;
`;

const BoxCategory = styled.div`
  width: 990px;
  height: 32px;
  background: linear-gradient(
    90deg,
    #d9d9d9 1.57%,
    rgba(217, 217, 217, 0) 100%
  );
  opacity: 0.8;
  border-radius: 7px;
  color: white;
  font-size: 22px;
  font-weight: bold;
  margin-top: 28px;
  padding-left: 10px;
`;

const Box_DZ = styled.div`
  margin: 10px 10px 0px 0px;
  width: 100px;
  height: 90px;
  background-color: #D9D9D9;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  color: black;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonGripper = styled.button`
  width: 240px;
  height: 100px;
  background-color: #ffffff;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px; 
  font-size: 24px;
  color: black;
  transition: 0.5s;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: #fff8f6;
    border: 1px solid #f84d27;
  }
`;

const Button1 = styled.button`
  font-size: 28px;
  color: black;
  margin: 0 10px 10px 0;
  width: 240px;
  height: 110px;
  border-radius: 8px;
  transition: 0.5s;
  background-color: #ffffff;
  border: none;
  font-weight: normal;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  background-image: url(${(props) => props.imageUrl});
  background-size: cover;

  &:hover {
    cursor: pointer;
    border: 1px solid #f84d27;
  }
`;

const ButtonTopping = styled.button`
width: 240px;
height: 100px;
background-color: #ffffff;
border: none;

box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
border-radius: 8px;
font-size: 28px;
color: black;
transition: 0.5s;

&:hover {
  cursor: pointer;
  font-weight: bold;
  background-color: #fff8f6;
  border: 1px solid #f84d27;
}
`;

const ButtonDriizzle = styled.button`
  width: 212px;
  height: 90px;
  background-color: #ffffff;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  font-size: 28px;
  color: black;
  transition: 0.5s;

  &:hover {
    cursor: pointer;
    font-weight: bold;
    background-color: #fff8f6;
    border: 1px solid #f84d27;
  }
`;


const Button4 = styled.button`
  width: 240px;
  height: 100px;
  background-color: #f3f3f3;
  border: none;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;