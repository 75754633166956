import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import DropDownButton from './DropDownButton';
import SearchButton from './SearchButton';
import ListComponents from './ListComponents';
import { FaTrashCan } from "react-icons/fa6";
import { steps } from "framer-motion";
import DeleteListModal from './DeleteListModal';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";




export default function Inventory() {
    const items = [{ 'id': '0', 'name': 'In stock', 'isChecked': false }, { 'id': '1', 'name': 'Low stock', 'isChecked': false }, { 'id': '2', 'name': 'Out of stock', 'isChecked': false }];

    const [originalData, setOriginalData] = useState([]);

    const locationId = useSelector((state) => state.locationId)

    const [copyData, setCopyData] = useState([...originalData]);
    const [isCheckedList, setIsCheckedList] = useState(items);

    const [search, setSearch] = useState('')

    useEffect(() => {

        const url = `${process.env.REACT_APP_API_SERVER_URL}/api/v1/${locationId}/inventory`;
        console.log("locationId : ", locationId)
        const aData = axios.get(url, {
        })
            .then(response => {
                console.log(response.data.data)
                setOriginalData([...response.data.data])
            })
            .catch(error => {
                console.log("성분 데이터를 불러오지 못했습니다.")

            }
            );
    }, [locationId]);


    useEffect(() => {
        // true 이면 해당 name 에 해당하는 item 목록으로 보여주기

        // true 걸러내기
        const arr = isCheckedList.filter((x) => {
            return x.isChecked == true;
        })

        if (arr.length === 0) {
            setCopyData(originalData);
            return;
        }

        // name 만 걸러내기
        var nameArr = []

        for (let i = 0; i < arr.length; i++) {
            let name = arr[i].name
            console.log("naem : " + name)

            for (let j = 0; j < originalData.length; j++) {
                if (name === "In stock") {
                    if (parseInt(originalData[j].currentQuantity) > 10) {
                        nameArr.push(originalData[j])
                    }

                } else if (name === "Low stock") {
                    if (parseInt(originalData[j].currentQuantity) <= 10 && parseInt(originalData[j].currentQuantity) > 0) {
                        nameArr.push(originalData[j])
                    }
                } else if (name === "Out of stock") {
                    if (parseInt(originalData[j].currentQuantity) === 0) {
                        nameArr.push(originalData[j])
                    }
                } else {

                }
            }
        }

        setCopyData(nameArr);

    }, [isCheckedList, originalData])

    useEffect(() => {

        const arr = isCheckedList.filter((x) => {
            return x.isChecked == true;
        })

        // 검색어가 원래 없던 경우와 있었는데 없는 경우를 나눠야한다.
        if (search === '') {
            if (arr.length === 0) {
                let equalItems = filterDataBySearch(originalData)

                setCopyData(equalItems)
                return;
            } else {
                var nameArr = filterDataByStatus(arr)

                let equalItems = filterDataBySearch(nameArr)

                setCopyData(equalItems)

            }

        }


        if (arr.length === 0) {
            let equalItems = filterDataBySearch(originalData)

            setCopyData(equalItems)
            return;
        } else {
            var nameArr = filterDataByStatus(arr)

            let equalItems = filterDataBySearch(nameArr)

            setCopyData(equalItems)
        }

    }, [search, isCheckedList])

    const changeIsCheckedList = (copyData) => {
        setIsCheckedList(copyData)
    }

    const changeSearch = (data) => {
        setSearch(data)

    }


    function filterDataByStatus(arr) {
        var nameArr = []

        for (let i = 0; i < arr.length; i++) {
            let name = arr[i].name
            console.log("naem : " + name)

            for (let j = 0; j < originalData.length; j++) {
                if (name === "In stock") {
                    if (parseInt(originalData[j].currentQuantity) > 10) {
                        nameArr.push(originalData[j])
                    }

                } else if (name === "Low stock") {
                    if (parseInt(originalData[j].currentQuantity) <= 10 && parseInt(originalData[j].currentQuantity) > 0) {
                        nameArr.push(originalData[j])
                    }
                } else if (name === "Out of stock") {
                    if (parseInt(originalData[j].currentQuantity) === 0) {
                        nameArr.push(originalData[j])
                    }
                } else {

                }
            }
        }

        return nameArr;
    }

    function filterDataBySearch(arr) {
        const filteredItems = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].ingredientName.includes(search)) {
                filteredItems.push(arr[i]);
            }
        }

        return filteredItems;
    }

    // 전체 선택 후 삭제 로직
    const [deleteList, setDeleteList] = useState([])
    const [openDeleteIcon, setOpenDeleteIcon] = useState(false);
    const [openDeleteListModal, setDeleteListModal] = useState(false);

    const handleDeleteIcon = (bool) => {

        setOpenDeleteIcon(bool)
    }

    const handleDeleteList = (list) => {

        const arr = [...list]
        setDeleteList(arr)
    }

    const clickDeleteIcon = () => {
        setDeleteListModal(true)
    }

    const handleDeleteListModal = () => {
        setDeleteListModal(false)
    }

    //

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
            <Header>
                <PageTitle>Inventory Dashboard</PageTitle>
                <Link to="/inventory/log">
                    <LogPageNavButton>Inventory Log</LogPageNavButton>
                </Link>
            </Header>
            <InventoryContainer>
                <TopContainer >
                    <DropDownButton buttonText="Click Me" items={isCheckedList} changeIsCheckedList={changeIsCheckedList} />
                    <SearchButton search={changeSearch}></SearchButton>

                    {
                        openDeleteIcon ?
                            <DeleteContainer onClick={(e) => clickDeleteIcon()}>
                                <FaTrashCan style={{ marginLeft: "2px" }}></FaTrashCan>
                                <div style={{ marginLeft: "8px" }}>Delete</div>
                            </DeleteContainer>
                            :
                            null
                    }

                </TopContainer>
                <MiddleContainer>
                    <DeleteListModal open={openDeleteListModal} handleChangeDeleteModal={handleDeleteListModal} deleteList={deleteList}></DeleteListModal>

                    <ListComponents items1={copyData} deleteIcon={handleDeleteIcon} deleteList={handleDeleteList}></ListComponents>

                </MiddleContainer>
                <BottomContainer>

                </BottomContainer>

            </InventoryContainer>
        </div>

    )
}

const PageTitle = styled.div`
font-size: 30px;
font-weight: bold;
margin-left: 25px;
margin-top: 20px;
width: 500px;
`

const InventoryContainer = styled.div`
width: 1200px;
height: 726px;
margin-left: 20px;
margin-top: 20px; 
// background: red;

`
const TopContainer = styled.div`
display: flex;
maring-left: 10px;
width: 100%;
height: 80px;
// background: red;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);

`

const MiddleContainer = styled.div`
width: 100%;
height: 566px;
// background: Orange;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);

`

const BottomContainer = styled.div`
width: 100%;
height: 80px;
background: white;
// box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);
box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25); /* 아래 그림자만 남김 */
border-top: 2px dashed #F5F5F5;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;

`
const DeleteContainer = styled.div`
width: 90px;
height: 40px;
color: #ff5631;

display: flex;
justify-content: center;
align-items: center;

border-radius: 5px;

margin-top: 26px;
margin-left: auto;
margin-right: 300px;

&:hover {
    background: #fff3ef;
}

`

const LogPageNavButton = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: flex-start;
    align-items: center;
    align-self: center;
    position: absolute;
    font-weight: bold;
    color: gray;
    z-index: 2;
    
    margin-top: 15px;

    right: 6px;
    // margin-left: auto;
    
    width: 120px;
    height: 30px;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);
    cursor: pointer;

    border: 1px solid transparent; /* 기본 테두리 설정 */
    background-color: #ffffff;


  &:hover {
    color: black; /* 마우스를 올릴 때 테두리 색상 변경 */ 
    border-color: gray; /* 마우스를 올릴 때 테두리 색상 변경 */ 

  }
`

const Header = styled.div`
  display: flex;
  position: relative;
  
  `