import React, { useState, useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { BsThreeDotsVertical } from "react-icons/bs";
import EditModalComponent from "./EditModalComponent"
import DeleteModalComponent from './DeleteModalComponent';
import axios from 'axios';



const ListComponents = ({ items1, deleteIcon, deleteList }) => {


    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setDeleteOpenModal] = useState(false);

    const [openOptions, setOpenOptions] = useState(items1.map(() => false));
    const [refIndex, setRefIndex] = useState();

    const wrapperRef = useRef([]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (wrapperRef.current[refIndex] && !wrapperRef.current[refIndex].contains(event.target)) {
                const newOpenOptions = openOptions.map(() => false);
                setOpenOptions(newOpenOptions);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {

            document.removeEventListener('click', handleClickOutside);
        };
    }, [wrapperRef.current[refIndex]]);


    const calucalateGraphBar = (items) => {
        let percent = items.currentQuantity / items.totalQuantity
        let result = 45 * (percent) // StockGraph width 길이가 바뀌면 45  또한 바뀐 길이에 맞춰 바뀌어야함. 


        return result;
    }

    const graphBackground = (items) => {
        const integerValue = parseInt(items.currentQuantity);
        switch (true) {
            case integerValue > 10:
                return '#d3f1d9';
            case integerValue > 0.1 && integerValue <= 10:
                return '#ffebce';
            case integerValue === 0:
                return '#ffd9cf';
            default:
                break;
        }
    }

    const graphForeground = (items) => {
        const integerValue = parseInt(items.currentQuantity);

        switch (true) {
            case integerValue > 10:
                return '#22c55d';
            case integerValue > 0.1 && integerValue <= 10:
                return '#ffab00';
            case integerValue === 0:
                return '';
            default:
                break;
        }
    }

    const statusText = (items) => {
        const integerValue = parseInt(items.currentQuantity);

        switch (true) {
            case integerValue > 10:
                return items.currentQuantity + " in stock";
            case integerValue > 0.1 && integerValue <= 10:
                return items.currentQuantity + " low stock";
            case integerValue === 0:
                return 'out of stock';
            default:
                break;
        }
    }

    /**
     *  모달창에서 확인 누르면 API 서버로 변경사항 
     */
    const handleChangeValue = () => {
        setOpenModal((prev) => !prev);
    };

    const handleChangeOptionModal = (index) => {
        const newOpenOptions = openOptions.map(() => false);

        newOpenOptions[index] = true;
 
        setOpenOptions(newOpenOptions);
        setRefIndex(index)
    };

    const [ selectedIngredient, setSelectedIngredient ] = useState(null);

    const passData = (item) => {
        console.log(item)
        setSelectedIngredient(item)
    }

    const [ selectedDeletIngredient, setSelectedDeleteIngredient ] = useState(null);

    const naknak = (isOpened, item) => {
        setDeleteOpenModal(isOpened)
        setSelectedDeleteIngredient(item.ingredientInventoryId)
    }
    const handleChangeDeleteModal = () => {
        setDeleteOpenModal((prev) => !prev);
    };


// 
    // 체크 아이템을 담을 배열
    const [checkItems, setCheckItems] = useState([]);

    // 체크박스 단일 선택
    const handleSingleCheck = (checked, id) => {
        if (checked) {
            const updateCheckItems = [...checkItems, id]
            setCheckItems(updateCheckItems);
            deleteIcon(true)
            

            deleteList(updateCheckItems)


        }else {
            setCheckItems(checkItems.filter((el) => el !== id));
            deleteList(checkItems.filter((el) => el !== id));
            if(checkItems.length <= 1){
                deleteIcon(false)
            }
        }
    }

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {

            const idArray = [];
            items1.forEach((el) => idArray.push(el.ingredientInventoryId));
            deleteList(idArray)
            setCheckItems(idArray);
            deleteIcon(true)

        }else {
            // 전체 선택 해제 시 checkItems를 빈 배열로 상태 업데이트
            setCheckItems([]);
            deleteList([]);
            deleteIcon(false)

        }
    }



//

    return (
        <>
            <HeaderDiv>
                <CheckBox type='checkbox' onChange={(e) => handleAllCheck(e.target.checked)} checked={checkItems.length === items1.length && items1.length !==0 ? true : false}></CheckBox>
                <NameBox>Ingredient</NameBox>
                <StockNameBox>Stock</StockNameBox>
            </HeaderDiv>
            <EditModalComponent open={openModal} changeValue={handleChangeValue} selectedIngredient={selectedIngredient}/>
            <DeleteModalComponent open={openDeleteModal} handleChangeDeleteModal={handleChangeDeleteModal} selectedDeletIngredientId={selectedDeletIngredient}></DeleteModalComponent>
            <ListContainer>
                {
                    items1.map((item, index) => (
                        <ListDiv key={index}>
                            <CheckBox type='checkbox' onChange={(e) => handleSingleCheck(e.target.checked , item.ingredientInventoryId)} checked={checkItems.includes(item.ingredientInventoryId) ? true : false}></CheckBox>
                            <IngredientName>
                                <IngredientTitle>{item.ingredientName}</IngredientTitle>
                                <IngredientCateogry>{item.category}</IngredientCateogry>
                            </IngredientName>
                            <StockBox>
                                <StockGraph style={{ background: graphBackground(item) }}></StockGraph>
                                <StockGraph2 style={{ width: calucalateGraphBar(item), background: graphForeground(item) }} ></StockGraph2>
                                <StockText>{statusText(item)}</StockText>
                            </StockBox>
                            <DotContainer ref={el => (wrapperRef.current[index] = el)} onClick={(e) => { handleChangeOptionModal(index); }} >
                                <StyledThreeDotsVertical />
                            </DotContainer>
                            <RightContainer key={index} scale={openOptions[index] ? '1': '0'} style={{ display: openOptions[index] ? 'flex' : 'none' }} >
                                <ModalButton onClick={(e) => {setOpenModal(true); passData(item)}}>Edit</ModalButton>
                                <ModalButton key={index} style={{color: 'red'}} onClick={ (e) => naknak(true, item)}>Delete</ModalButton>
                            </RightContainer>
                        </ListDiv>
                    ))}
            </ListContainer>
        </>
    );
};

export default ListComponents;



const CheckBox = styled.input`
background: blue;
margin-left: 18px;
transform: scale(1.4);
z-index: 0; 
`;

const NameBox = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
padding-left: 7px;


width: 160px;
margin-left: 10px;
border-left: 2px solid transparent;
border-left: 2px solid transparent;

`

const StockNameBox = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;

padding-left: 7px;

width: 150px;
height: 15px;
margin-right: 150px; 
margin-left: auto;

border-right: 2px solid;
border-left: 2px solid;
border-color: transparent;

&:hover {
    border-color: gray;
    border-left: 2px solid gray;
    border-right: 2px solid gray;
}
`

const HeaderDiv = styled.div`
display: flex;
justify-content: flex-start;
width: 100%;
height: 50px;
background: #F5F5F5;
align-items: center;

// border-top-left-radius: 5px;
// border-top-right-radius: 5px;

&:hover  {

    /* NameBox에 스타일 적용 */
    ${NameBox} {
        border-color: #CCCCCC;
        border-left: 2px solid  #CCCCCC;
        border-right: 2px solid  #CCCCCC;
    }

    ${StockNameBox} {
        border-color: #CCCCCC;
        border-left: 2px solid  #CCCCCC;
        border-right: 2px solid  #CCCCCC;
    }


`

const ListContainer = styled.div`
width: 100%;
height: 516px;
background: white;
max-height: 516px; /* 최대 높이 설정 */
overflow: auto; /* 내용이 넘치면 스크롤이 생김 */
overflow-x: hidden;
`


const ListDiv = styled.div`
position: relative;
display: flex;
align-items: center;
// margin-left: 13.5px;
width: 1200px;
height: 80px;
background: white;
border-bottom: 2px dashed #F5F5F5; /* 점선 테두리, 회색 */

&:hover {
    background: #F5F5F5;
}
`

const IngredientName = styled.div`
display: flex;
justify-content: flex-start;
align-items: left;
flex-direction: column;

white-space: pre-wrap;
padding-left: 9px;
margin-left: 10px;

width: 837px;
// background: white;

`
const IngredientTitle = styled.div`
font-size: 19px;
`

const IngredientCateogry = styled.div`
font-size: 14px;
color: gray;
`

const StockBox = styled.div`
position: relative;
display: flex;
align-items: left;
flex-direction: column;

// background: blue;

padding-left: 7px;
width: 220px;
height: 50px;
border: 2px solid transparent;

&::before {
  content: '';
  position: absolute;
  top: -5px; /* 클릭 영역을 위쪽으로 확장 */
  left: -5px; /* 클릭 영역을 왼쪽으로 확장 */
  right: -5px; /* 클릭 영역을 오른쪽으로 확장 */
  bottom: -5px; /* 클릭 영역을 아래쪽으로 확장 */
}
`

const StockGraph = styled.div`
width: 45px;
height: 5px;
background: #d3f1d9;
margin-top: 15px;
border-radius: 3px;
z-index: 1;

`

const StockGraph2 = styled.div`
width: 30px;
height: 5px;
background: #22c55d;
margin-top: -5px;

border-radius: 3px;
z-index: 2;
`

const StockText = styled.div`
margin-top: 8px;
font-size: 10px;
color: gray;
`

const DotContainer = styled.div`

display: flex;
justify-content: center;
align-items: center;
// right: -22px;
left: auto;
flex-direction: column;


border-radius: 15px;
width: 34px;
height: 34px;

&:hover {
    opacity: 0.8; /* 호버 시 투명도 조정 */
    background: #DCDCDC;
}
`

const StyledThreeDotsVertical = styled(BsThreeDotsVertical)`

`;


const RightContainer = styled.div`
display: flex;
position: absolute;
left: 1050px;
height: 90px;
width: 100px;
z-index: 5;
margin-top: 130px;
margin-right: 55px;


background: white;
border-radius: 5px;
box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1), 1px 4px 4px rgba(0, 0, 0, 0.25);


flex-direction: column;
justify-content: center;
align-items: center;

animation: modalUp 0.2s linear;
@keyframes modalUp {

     0% { transform: translateX(0); }
     25% { transform: translateX(-5px) rotate(5deg); }
     50% { transform: translateX(5px) rotate(-5deg); }
     75% { transform: translateX(-5px) rotate(5deg); }
     100% { transform: translateX(0); }
    }
`

const ModalButton = styled.div`
display: flex;
justify-content: center;
text-align: center;
align-items: center;

background: white;
width: 95%;
height: 45%;
border-radius: 10px;
margin-top: 2px;

&:hover {
    font-weight: bold;
    background: #DCDCDC;
}
`

